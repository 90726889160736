import { Color, ThemeTypography } from '@instacart/ids-core'
import { FunctionComponent, useState, useEffect, HTMLProps } from 'react'
import { CAMPAIGN_FORMAT_FIELD_VALUES } from 'common/campaignHelpers'
import { PROVI_SELF_SERVE_TERMS_URL } from 'common/constants'
import { ValueOf } from 'common/types'
import useIntl from 'common/useIntl'
import FormattedMessage from 'components/FormattedMessage'
import PrivacyTerms from 'components/TermsAndNotices/PrivacyTerms'
import { useAuthContext, isProviAccount } from 'context'
import { getAccountClient } from 'service/account'
import { GetAccountDocumentFilenameEnum as AccountDocumentEnum } from 'service/openapi/__codegen__/apis/AccountsApi'
import { GetAdminAccountsResponseDataAttributesAccountTypeEnum as AccountTypes } from 'service/openapi/__codegen__/models/GetAdminAccountsResponseDataAttributes'
import { TermsAndConditions } from '../molecules'

export const CAMPAIGN_CREATION_LEGAL_TYPES = {
  DISPLAY: 'display',
  FEATURED_PRODUCT: 'featured_product',
  VIDEO_DISPLAY: 'video_display',
  INSPIRATION: 'inspiration',
  SMART: 'smart',
} as const

export type CampaignCreationType = ValueOf<typeof CAMPAIGN_CREATION_LEGAL_TYPES>

export const getTermsFromGoalFormat = (goalFormat: string): CampaignCreationType | undefined => {
  switch (goalFormat) {
    case CAMPAIGN_FORMAT_FIELD_VALUES.sponsoredProduct:
      return CAMPAIGN_CREATION_LEGAL_TYPES.FEATURED_PRODUCT
    case CAMPAIGN_FORMAT_FIELD_VALUES.inspiration:
      return CAMPAIGN_CREATION_LEGAL_TYPES.INSPIRATION
    case CAMPAIGN_FORMAT_FIELD_VALUES.smart:
      return CAMPAIGN_CREATION_LEGAL_TYPES.SMART
    case CAMPAIGN_FORMAT_FIELD_VALUES.shoppableVideo:
      return CAMPAIGN_CREATION_LEGAL_TYPES.VIDEO_DISPLAY
    case CAMPAIGN_FORMAT_FIELD_VALUES.displayBanner:
    case CAMPAIGN_FORMAT_FIELD_VALUES.shoppableDisplay:
      return CAMPAIGN_CREATION_LEGAL_TYPES.DISPLAY
    default:
      return undefined
  }
}

type TermsProps = Pick<HTMLProps<HTMLDivElement>, 'css' | 'children'> & {
  includeBudgetTerms?: boolean
  includeKeywordBiddingTerms?: boolean
  includeBidLandscapeDisclaimer?: boolean
  campaignCreationType?: CampaignCreationType
  color?: Color
  typography?: keyof ThemeTypography
}

export const CampaignTermsAndConditions: FunctionComponent<React.PropsWithChildren<TermsProps>> = ({
  includeBudgetTerms,
  includeKeywordBiddingTerms,
  includeBidLandscapeDisclaimer,
  campaignCreationType = CAMPAIGN_CREATION_LEGAL_TYPES.FEATURED_PRODUCT,
  css,
  color = 'systemGrayscale70',
  typography = 'bodySmall2',
}) => {
  const { formatMessage } = useIntl()
  const authContext = useAuthContext()
  const [termsFPUrl, setTermsFPUrl] = useState('')
  const [termsDisplayUrl, setTermsDisplayUrl] = useState('')
  const [additionalTermsDisplayUrl, setAdditionalTermsDisplayUrl] = useState('')
  const [additionalTermsVideoDisplayUrl, setAdditionalTermsVideoDisplayUrl] = useState('')
  const [additionalTermsInspirationUrl, setAdditionalTermsInspirationUrl] = useState('')
  const [termsUrl, setTermsUrl] = useState('')
  const [isAlcohol, setIsAlcohol] = useState(false)
  const [isRTDAccount, setIsRTDAccount] = useState(false)
  const isProvi = isProviAccount(authContext)

  useEffect(() => {
    const accountId = authContext?.user?.currentAccount?.uuid || ''
    const isAgency = authContext?.user?.type === 'agency'

    const getAccount = async () => {
      const AccountClient = await getAccountClient()
      const res = await AccountClient.required.getAccount()

      const { alcohol } = res?.data?.attributes || {}
      const msaString = isAgency ? AccountDocumentEnum.AgencyMsa : AccountDocumentEnum.AdvertiserMsa

      setTermsUrl(AccountClient.required.documentURI(msaString))
      setTermsFPUrl(AccountClient.required.documentURI(AccountDocumentEnum.TermsAndConditions))
      setTermsDisplayUrl(AccountClient.required.documentURI(AccountDocumentEnum.DisplayMsa))
      setAdditionalTermsDisplayUrl(
        AccountClient.required.documentURI(AccountDocumentEnum.DisplayAdditionalTerms)
      )
      setAdditionalTermsVideoDisplayUrl(
        AccountClient.required.documentURI(AccountDocumentEnum.VideoDisplayAdditionalTerms)
      )
      setAdditionalTermsInspirationUrl(
        AccountClient.required.documentURI(AccountDocumentEnum.InspirationAdditionalTerms)
      )
      setIsAlcohol(!!alcohol)
      setIsRTDAccount(authContext?.user?.currentAccount?.accountType === AccountTypes.AdvertiserRtd)
    }
    if (accountId) {
      getAccount()
    }
  }, [authContext])

  const ProviTermsAndConditions = () => {
    return (
      <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
        {formatMessage(
          { id: 'pages.campaignPayment.legal.proviTerms' },
          {
            linkedTerms: (
              <a href={PROVI_SELF_SERVE_TERMS_URL} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="pages.campaignPayment.legal.proviTerms.name" />
              </a>
            ),
          }
        )}
      </TermsAndConditions>
    )
  }

  switch (campaignCreationType) {
    case 'display':
      if (isProvi) {
        return <ProviTermsAndConditions />
      }
      if (isAlcohol) {
        return (
          <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
            {formatMessage(
              { id: 'pages.campaignPayment.legal.displayFullTermsAlcohol' },
              {
                linkedTerms: (
                  <a href={termsDisplayUrl} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="pages.campaignPayment.legal.searchBannerTermsAlcohol" />
                  </a>
                ),
              }
            )}
          </TermsAndConditions>
        )
      }
      return (
        <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
          {isRTDAccount && (
            <>
              <FormattedMessage id="pages.campaignPayment.legal.shareDataForRTDAccount" />{' '}
            </>
          )}
          {formatMessage(
            { id: 'pages.campaignPayment.legal.displayFullTerms' },
            {
              submitForReview: (
                <FormattedMessage id="pages.campaignPayment.legal.submitAndReview" />
              ),
              linkedTerms: (
                <a href={termsDisplayUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.display.searchBannerTerms" />
                </a>
              ),
              linkedAdditionalTerms: (
                <a href={additionalTermsDisplayUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.display.searchBannerAdditionalTerms" />
                </a>
              ),
              privacyPolicy: (
                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="common.privacyPolicy" />
                </a>
              ),
            }
          )}
        </TermsAndConditions>
      )
    case 'video_display':
      return (
        <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
          {formatMessage(
            { id: 'pages.campaignPayment.legal.videoDisplay.videoDisplayFullTerms' },
            {
              submitForReview: (
                <FormattedMessage id="pages.campaignPayment.legal.submitAndReview" />
              ),
              linkedTerms: (
                <a href={termsDisplayUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.videoDisplay.videoDisplayTerms" />
                </a>
              ),
              linkedAdditionalTerms: (
                <a href={additionalTermsVideoDisplayUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.videoDisplay.videoDisplayAdditionalTerms" />
                </a>
              ),
              privacyPolicy: (
                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="common.privacyPolicy" />
                </a>
              ),
            }
          )}
        </TermsAndConditions>
      )
    case 'featured_product':
      if (isProvi) {
        return <ProviTermsAndConditions />
      }
      if (isAlcohol) {
        return (
          <TermsAndConditions color={color} typography={typography} css={css}>
            <FormattedMessage id="pages.campaignPayment.legal.main1" />
            <a href={termsUrl} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="pages.campaignPayment.legal.termsAndConditions" />
            </a>{' '}
            <FormattedMessage id="pages.campaignPayment.legal.alcohol" />{' '}
          </TermsAndConditions>
        )
      }

      return (
        <TermsAndConditions color={color} typography={typography} css={css}>
          {isRTDAccount && (
            <>
              <FormattedMessage id="pages.campaignPayment.legal.shareDataForRTDAccount" />{' '}
            </>
          )}
          {includeBudgetTerms && (
            <>
              <FormattedMessage id="pages.campaignPayment.legal.budget" />{' '}
            </>
          )}
          {includeBidLandscapeDisclaimer && (
            <>
              <FormattedMessage id="pages.campaignPayment.legal.bidLandscape" />
              <br />
              <br />
            </>
          )}
          <FormattedMessage id="pages.campaignPayment.legal.main1" />
          <a href={termsUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="pages.campaignPayment.legal.termsAndConditions" />
          </a>{' '}
          <FormattedMessage id="pages.campaignPayment.legal.main2" />
          <a href={termsFPUrl} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="pages.campaignPayment.legal.sponsoredProductTerms" />
          </a>
          {'. '}
          <FormattedMessage id="pages.campaignPayment.legal.main3" />
          {includeKeywordBiddingTerms && (
            <FormattedMessage id="pages.campaignPayment.legal.keywordBidding1" />
          )}
          <FormattedMessage id="pages.campaignPayment.legal.main4" /> <PrivacyTerms />
        </TermsAndConditions>
      )
    case 'inspiration':
      return (
        <TermsAndConditions color="systemGrayscale70" typography="bodySmall2" css={css}>
          {formatMessage(
            { id: 'pages.campaignPayment.legal.inspirationFullTerms' },
            {
              submitForReview: (
                <FormattedMessage id="pages.campaignPayment.legal.submitAndReview" />
              ),
              linkedTerms: (
                <a href={termsDisplayUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.display.searchBannerTerms" />
                </a>
              ),
              linkedAdditionalTerms: (
                <a href={additionalTermsInspirationUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="pages.campaignPayment.legal.inspiration.additionalTerms" />
                </a>
              ),
              privacyPolicy: (
                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="common.privacyPolicy" />
                </a>
              ),
            }
          )}
        </TermsAndConditions>
      )
    case CAMPAIGN_CREATION_LEGAL_TYPES.SMART:
      return (
        <TermsAndConditions
          data-testid="universal-legal-text"
          color="systemGrayscale70"
          typography="bodySmall2"
          css={css}
        >
          {isRTDAccount && (
            <>
              <FormattedMessage id="pages.campaignPayment.legal.shareDataForRTDAccount" />{' '}
            </>
          )}
          {
            <FormattedMessage
              id="pages.campaignPayment.legal.smart.terms"
              useBreakFormatting
              values={{
                linkedTerms: (
                  <a href={termsDisplayUrl} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="pages.campaignPayment.legal.display.searchBannerTerms" />
                  </a>
                ),
                sponsoredProductTerms: (
                  <a href={termsFPUrl} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="pages.campaignPayment.legal.sponsoredProductTerms" />
                  </a>
                ),
                displayTerms: (
                  <a href={termsFPUrl} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="pages.campaignPayment.legal.display.searchBannerAdditionalTerms" />
                  </a>
                ),
                privacyPolicy: (
                  <a href="/privacy" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="common.privacyPolicy" />
                  </a>
                ),
              }}
            />
          }
        </TermsAndConditions>
      )
  }
}
