// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
 */
export interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  brandPageClick?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClick
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  urlClick?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClick
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
   */
  recipeClick?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClick
}

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick interface.
 */
export function instanceOfGetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick {
  if (json === undefined || json === null) {
    return json
  }
  return {
    brandPageClick: !exists(json, 'brand_page_click')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickFromJSON(
          json['brand_page_click']
        ),
    urlClick: !exists(json, 'url_click')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickFromJSON(
          json['url_click']
        ),
    recipeClick: !exists(json, 'recipe_click')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickFromJSON(
          json['recipe_click']
        ),
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    brand_page_click:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickBrandPageClickToJSON(
        value.brandPageClick
      ),
    url_click:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickUrlClickToJSON(
        value.urlClick
      ),
    recipe_click:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickRecipeClickToJSON(
        value.recipeClick
      ),
  }
}
