// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsParamDataAttributesAdGroups } from './GetDisplayCampaignsParamDataAttributesAdGroups'
import {
  GetDisplayCampaignsParamDataAttributesAdGroupsFromJSON,
  GetDisplayCampaignsParamDataAttributesAdGroupsFromJSONTyped,
  GetDisplayCampaignsParamDataAttributesAdGroupsToJSON,
} from './GetDisplayCampaignsParamDataAttributesAdGroups'

/**
 *
 * @export
 * @interface GetDisplayCampaignsParamDataAttributes
 */
export interface GetDisplayCampaignsParamDataAttributes {
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  name: string
  /**
   * Send display for this field.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  campaignType: GetDisplayCampaignsParamDataAttributesCampaignTypeEnum
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active. This field cannot be set to true for a campaign in draft state. Draft campaign can only be launched from Instacart Ads Manager.
   * @type {boolean}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  enabled: boolean
  /**
   * Send consideration, awareness, sales for this field.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  objective: GetDisplayCampaignsParamDataAttributesObjectiveEnum
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  budget: number
  /**
   * Send paced, asap, pacing_type_unknown for this field.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  pacingType: GetDisplayCampaignsParamDataAttributesPacingTypeEnum
  /**
   * The status of the campaign
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  status: GetDisplayCampaignsParamDataAttributesStatusEnum
  /**
   * The campaign’s start date in iso8601 format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  startsAt: string
  /**
   * The campaign's effective end date in iso8601 format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign's budget has been spent.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  endsAt: string
  /**
   * The campaign’s start date in the 'YYYY-MM-DD' format. Campaigns start midnight, PST, on the specified date.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  scheduledStartAtDate: string
  /**
   * The campaign's effective end date in the 'YYYY-MM-DD' format. Campaigns end at midnight, PST, on the specified date. The campaign only stops when the campaign’s budget has been spent.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  scheduledEndAtDate: string
  /**
   * Memo for the campaign
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  memo?: string
  /**
   * Puchase Order #
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  purchaseOrder?: string
  /**
   * ID of the account that is linked to this campaign
   * @type {number}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  accountId: number
  /**
   * The campaign’s goal format
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  goalFormat: string
  /**
   * The campaign’s goal
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  goal: string
  /**
   * The ad format for the campaign, can be either Display Ad or Shoppable Display
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  adFormat?: string
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  billPayer?: string
  /**
   * How you pay for the campaign
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  paymentType?: string
  /**
   * Pacing status of the campaign
   * @type {string}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  pacingStatus?: string
  /**
   * The list of ad groups within the campaign
   * @type {Array<GetDisplayCampaignsParamDataAttributesAdGroups>}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  adGroups?: Array<GetDisplayCampaignsParamDataAttributesAdGroups>
  /**
   * Whether the campaign contains a rejected ad group.
   * @type {boolean}
   * @memberof GetDisplayCampaignsParamDataAttributes
   */
  containsRejectedAdGroup?: boolean
}

/**
 * @export
 */
export const GetDisplayCampaignsParamDataAttributesCampaignTypeEnum = {
  Display: 'display',
} as const
export type GetDisplayCampaignsParamDataAttributesCampaignTypeEnum =
  typeof GetDisplayCampaignsParamDataAttributesCampaignTypeEnum[keyof typeof GetDisplayCampaignsParamDataAttributesCampaignTypeEnum]

/**
 * @export
 */
export const GetDisplayCampaignsParamDataAttributesObjectiveEnum = {
  Consideration: 'consideration',
  Awareness: 'awareness',
  Sales: 'sales',
} as const
export type GetDisplayCampaignsParamDataAttributesObjectiveEnum =
  typeof GetDisplayCampaignsParamDataAttributesObjectiveEnum[keyof typeof GetDisplayCampaignsParamDataAttributesObjectiveEnum]

/**
 * @export
 */
export const GetDisplayCampaignsParamDataAttributesPacingTypeEnum = {
  Paced: 'paced',
  Asap: 'asap',
  PacingTypeUnknown: 'pacing_type_unknown',
} as const
export type GetDisplayCampaignsParamDataAttributesPacingTypeEnum =
  typeof GetDisplayCampaignsParamDataAttributesPacingTypeEnum[keyof typeof GetDisplayCampaignsParamDataAttributesPacingTypeEnum]

/**
 * @export
 */
export const GetDisplayCampaignsParamDataAttributesStatusEnum = {
  Active: 'active',
  Ended: 'ended',
  OutOfBudget: 'out_of_budget',
  Paused: 'paused',
  Scheduled: 'scheduled',
  Unknown: 'unknown',
} as const
export type GetDisplayCampaignsParamDataAttributesStatusEnum =
  typeof GetDisplayCampaignsParamDataAttributesStatusEnum[keyof typeof GetDisplayCampaignsParamDataAttributesStatusEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsParamDataAttributes interface.
 */
export function instanceOfGetDisplayCampaignsParamDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'campaignType' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'objective' in value
  isInstance = isInstance && 'budget' in value
  isInstance = isInstance && 'pacingType' in value
  isInstance = isInstance && 'status' in value
  isInstance = isInstance && 'startsAt' in value
  isInstance = isInstance && 'endsAt' in value
  isInstance = isInstance && 'scheduledStartAtDate' in value
  isInstance = isInstance && 'scheduledEndAtDate' in value
  isInstance = isInstance && 'accountId' in value
  isInstance = isInstance && 'goalFormat' in value
  isInstance = isInstance && 'goal' in value

  return isInstance
}

export function GetDisplayCampaignsParamDataAttributesFromJSON(
  json: any
): GetDisplayCampaignsParamDataAttributes {
  return GetDisplayCampaignsParamDataAttributesFromJSONTyped(json, false)
}

export function GetDisplayCampaignsParamDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsParamDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    campaignType: json['campaign_type'],
    enabled: json['enabled'],
    objective: json['objective'],
    budget: json['budget'],
    pacingType: json['pacing_type'],
    status: json['status'],
    startsAt: json['starts_at'],
    endsAt: json['ends_at'],
    scheduledStartAtDate: json['scheduled_start_at_date'],
    scheduledEndAtDate: json['scheduled_end_at_date'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    purchaseOrder: !exists(json, 'purchase_order') ? undefined : json['purchase_order'],
    accountId: json['account_id'],
    goalFormat: json['goal_format'],
    goal: json['goal'],
    adFormat: !exists(json, 'ad_format') ? undefined : json['ad_format'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    paymentType: !exists(json, 'payment_type') ? undefined : json['payment_type'],
    pacingStatus: !exists(json, 'pacing_status') ? undefined : json['pacing_status'],
    adGroups: !exists(json, 'ad_groups')
      ? undefined
      : (json['ad_groups'] as Array<any>).map(
          GetDisplayCampaignsParamDataAttributesAdGroupsFromJSON
        ),
    containsRejectedAdGroup: !exists(json, 'contains_rejected_ad_group')
      ? undefined
      : json['contains_rejected_ad_group'],
  }
}

export function GetDisplayCampaignsParamDataAttributesToJSON(
  value?: GetDisplayCampaignsParamDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    campaign_type: value.campaignType,
    enabled: value.enabled,
    objective: value.objective,
    budget: value.budget,
    pacing_type: value.pacingType,
    status: value.status,
    starts_at: value.startsAt,
    ends_at: value.endsAt,
    scheduled_start_at_date: value.scheduledStartAtDate,
    scheduled_end_at_date: value.scheduledEndAtDate,
    memo: value.memo,
    purchase_order: value.purchaseOrder,
    account_id: value.accountId,
    goal_format: value.goalFormat,
    goal: value.goal,
    ad_format: value.adFormat,
    bill_payer: value.billPayer,
    payment_type: value.paymentType,
    pacing_status: value.pacingStatus,
    ad_groups:
      value.adGroups === undefined
        ? undefined
        : (value.adGroups as Array<any>).map(GetDisplayCampaignsParamDataAttributesAdGroupsToJSON),
    contains_rejected_ad_group: value.containsRejectedAdGroup,
  }
}
