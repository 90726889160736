// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetDisplayCampaignsIdParamBrandPageBlockSnapshot,
  instanceOfGetDisplayCampaignsIdParamBrandPageBlockSnapshot,
  GetDisplayCampaignsIdParamBrandPageBlockSnapshotFromJSON,
  GetDisplayCampaignsIdParamBrandPageBlockSnapshotFromJSONTyped,
  GetDisplayCampaignsIdParamBrandPageBlockSnapshotToJSON,
} from './GetDisplayCampaignsIdParamBrandPageBlockSnapshot'
import {
  GetDisplayCampaignsIdParamBrandPageSnapshot,
  instanceOfGetDisplayCampaignsIdParamBrandPageSnapshot,
  GetDisplayCampaignsIdParamBrandPageSnapshotFromJSON,
  GetDisplayCampaignsIdParamBrandPageSnapshotFromJSONTyped,
  GetDisplayCampaignsIdParamBrandPageSnapshotToJSON,
} from './GetDisplayCampaignsIdParamBrandPageSnapshot'
import {
  GetDisplayCampaignsIdParamCreativeSnapshot,
  instanceOfGetDisplayCampaignsIdParamCreativeSnapshot,
  GetDisplayCampaignsIdParamCreativeSnapshotFromJSON,
  GetDisplayCampaignsIdParamCreativeSnapshotFromJSONTyped,
  GetDisplayCampaignsIdParamCreativeSnapshotToJSON,
} from './GetDisplayCampaignsIdParamCreativeSnapshot'
import {
  GetDisplayCampaignsIdParamDisplayAdGroupSnapshot,
  instanceOfGetDisplayCampaignsIdParamDisplayAdGroupSnapshot,
  GetDisplayCampaignsIdParamDisplayAdGroupSnapshotFromJSON,
  GetDisplayCampaignsIdParamDisplayAdGroupSnapshotFromJSONTyped,
  GetDisplayCampaignsIdParamDisplayAdGroupSnapshotToJSON,
} from './GetDisplayCampaignsIdParamDisplayAdGroupSnapshot'
import {
  GetDisplayCampaignsIdParamDisplayAssetSnapshot,
  instanceOfGetDisplayCampaignsIdParamDisplayAssetSnapshot,
  GetDisplayCampaignsIdParamDisplayAssetSnapshotFromJSON,
  GetDisplayCampaignsIdParamDisplayAssetSnapshotFromJSONTyped,
  GetDisplayCampaignsIdParamDisplayAssetSnapshotToJSON,
} from './GetDisplayCampaignsIdParamDisplayAssetSnapshot'
import {
  GetDisplayCampaignsIdParamDisplayProductAssetSnapshot,
  instanceOfGetDisplayCampaignsIdParamDisplayProductAssetSnapshot,
  GetDisplayCampaignsIdParamDisplayProductAssetSnapshotFromJSON,
  GetDisplayCampaignsIdParamDisplayProductAssetSnapshotFromJSONTyped,
  GetDisplayCampaignsIdParamDisplayProductAssetSnapshotToJSON,
} from './GetDisplayCampaignsIdParamDisplayProductAssetSnapshot'

/**
 * @type GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot
 *
 * @export
 */
export type GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot =

    | ({ type: 'ASSET' } & GetDisplayCampaignsIdParamDisplayAssetSnapshot)
    | ({ type: 'BRAND_PAGE' } & GetDisplayCampaignsIdParamBrandPageSnapshot)
    | ({ type: 'BRAND_PAGE_BLOCK' } & GetDisplayCampaignsIdParamBrandPageBlockSnapshot)
    | ({ type: 'CREATIVE' } & GetDisplayCampaignsIdParamCreativeSnapshot)
    | ({ type: 'DISPLAY_AD_GROUP' } & GetDisplayCampaignsIdParamDisplayAdGroupSnapshot)
    | ({ type: 'PRODUCT_ASSET' } & GetDisplayCampaignsIdParamDisplayProductAssetSnapshot)

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...GetDisplayCampaignsIdParamDisplayAssetSnapshotFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...GetDisplayCampaignsIdParamBrandPageSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetDisplayCampaignsIdParamBrandPageBlockSnapshotFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...GetDisplayCampaignsIdParamCreativeSnapshotFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetDisplayCampaignsIdParamDisplayAdGroupSnapshotFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetDisplayCampaignsIdParamDisplayProductAssetSnapshotFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot exists with 'type=${json['type']}'`
      )
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetDisplayCampaignsIdParamDisplayAssetSnapshotToJSON(value)
    case 'BRAND_PAGE':
      return GetDisplayCampaignsIdParamBrandPageSnapshotToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetDisplayCampaignsIdParamBrandPageBlockSnapshotToJSON(value)
    case 'CREATIVE':
      return GetDisplayCampaignsIdParamCreativeSnapshotToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetDisplayCampaignsIdParamDisplayAdGroupSnapshotToJSON(value)
    case 'PRODUCT_ASSET':
      return GetDisplayCampaignsIdParamDisplayProductAssetSnapshotToJSON(value)
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot exists with 'type=${value['type']}'`
      )
  }
}
