// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviews } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviews'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviewsFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviewsFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviewsToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviews'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReview } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReview'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReviewFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReviewFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReviewToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReview'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState
 */
export interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState {
  /**
   * The number of consensus reviews required for this entity
   * @type {number}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState
   */
  requiredConsensusReviewCount?: number
  /**
   *
   * @type {Array<GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviews>}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState
   */
  consensusReviews?: Array<GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviews>
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReview}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState
   */
  finalReview?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReview
}

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState interface.
 */
export function instanceOfGetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState {
  if (json === undefined || json === null) {
    return json
  }
  return {
    requiredConsensusReviewCount: !exists(json, 'required_consensus_review_count')
      ? undefined
      : json['required_consensus_review_count'],
    consensusReviews: !exists(json, 'consensus_reviews')
      ? undefined
      : (json['consensus_reviews'] as Array<any>).map(
          GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviewsFromJSON
        ),
    finalReview: !exists(json, 'final_review')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReviewFromJSON(
          json['final_review']
        ),
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    required_consensus_review_count: value.requiredConsensusReviewCount,
    consensus_reviews:
      value.consensusReviews === undefined
        ? undefined
        : (value.consensusReviews as Array<any>).map(
            GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateConsensusReviewsToJSON
          ),
    final_review:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFinalReviewToJSON(
        value.finalReview
      ),
  }
}
