// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalytics } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalytics'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalytics'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreative } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreative'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreative'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategy } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategy'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategyFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategyFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategyToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategy'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
 */
export interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes {
  /**
   * A descriptive name for the ad group.
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  name: string
  /**
   * The maximum amount, in dollars, you want to pay per thousands views for advertised creatives in this ad group.
   * @type {number}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  defaultBid?: number
  /**
   * Id of the campaign the ad group belongs to. Required for creation
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  campaignId: string
  /**
   * Format of the ad group, ex: search banner, category banner
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  placementType: string
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreative}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  creative: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreative
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategy}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  targetingStrategy: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategy
  /**
   * Lock key for optimistic locking. Lock key retrieved in the response should be passed in the request. Only for updates
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  lockKey?: string
  /**
   * Enabled flag for the ad group. Ad group is disabled/paused if set to false.
   * @type {boolean}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  enabled?: boolean
  /**
   * Ad group status
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  status?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesStatusEnum
  /**
   * Timestamp of when the ad group was created
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  createdAt?: string
  /**
   * Timestamp of when the ad group was updated
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  updatedAt?: string
  /**
   * Editorial states
   * @type {Array<GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates>}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  editorialStates?: Array<GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates>
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalytics}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  analytics?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalytics
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
   */
  brandPage?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPage
}

/**
 * @export
 */
export const GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesStatusEnum = {
  Active: 'ACTIVE',
  Appealed: 'APPEALED',
  Approved: 'APPROVED',
  Closed: 'CLOSED',
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  Editable: 'EDITABLE',
  Ended: 'ENDED',
  Escalated: 'ESCALATED',
  InReview: 'IN_REVIEW',
  OutOfBudget: 'OUT_OF_BUDGET',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
  Scheduled: 'SCHEDULED',
  Unknown: 'UNKNOWN',
} as const
export type GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesStatusEnum =
  typeof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesStatusEnum[keyof typeof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesStatusEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes interface.
 */
export function instanceOfGetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'campaignId' in value
  isInstance = isInstance && 'placementType' in value
  isInstance = isInstance && 'creative' in value
  isInstance = isInstance && 'targetingStrategy' in value

  return isInstance
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesFromJSONTyped(json, false)
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    defaultBid: !exists(json, 'default_bid') ? undefined : json['default_bid'],
    campaignId: json['campaign_id'],
    placementType: json['placement_type'],
    creative: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeFromJSON(
      json['creative']
    ),
    targetingStrategy:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategyFromJSON(
        json['targeting_strategy']
      ),
    lockKey: !exists(json, 'lock_key') ? undefined : json['lock_key'],
    enabled: !exists(json, 'enabled') ? undefined : json['enabled'],
    status: !exists(json, 'status') ? undefined : json['status'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    editorialStates: !exists(json, 'editorial_states')
      ? undefined
      : (json['editorial_states'] as Array<any>).map(
          GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesFromJSON
        ),
    analytics: !exists(json, 'analytics')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsFromJSON(
          json['analytics']
        ),
    brandPage: !exists(json, 'brand_page')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageFromJSON(
          json['brand_page']
        ),
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    default_bid: value.defaultBid,
    campaign_id: value.campaignId,
    placement_type: value.placementType,
    creative: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeToJSON(
      value.creative
    ),
    targeting_strategy:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesTargetingStrategyToJSON(
        value.targetingStrategy
      ),
    lock_key: value.lockKey,
    enabled: value.enabled,
    status: value.status,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    editorial_states:
      value.editorialStates === undefined
        ? undefined
        : (value.editorialStates as Array<any>).map(
            GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesToJSON
          ),
    analytics: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsToJSON(
      value.analytics
    ),
    brand_page: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageToJSON(
      value.brandPage
    ),
  }
}
