// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { AdGroupKeywordsBulkResponse } from '../models/AdGroupKeywordsBulkResponse'
import {
  AdGroupKeywordsBulkResponseFromJSON,
  AdGroupKeywordsBulkResponseToJSON,
} from '../models/AdGroupKeywordsBulkResponse'
import type { AdGroupKeywordsSuggestedBidsResponse } from '../models/AdGroupKeywordsSuggestedBidsResponse'
import {
  AdGroupKeywordsSuggestedBidsResponseFromJSON,
  AdGroupKeywordsSuggestedBidsResponseToJSON,
} from '../models/AdGroupKeywordsSuggestedBidsResponse'
import type { ApiAdGroupKeywordsControllerBulkCreateInput } from '../models/ApiAdGroupKeywordsControllerBulkCreateInput'
import {
  ApiAdGroupKeywordsControllerBulkCreateInputFromJSON,
  ApiAdGroupKeywordsControllerBulkCreateInputToJSON,
} from '../models/ApiAdGroupKeywordsControllerBulkCreateInput'
import type { ApiAdGroupKeywordsControllerBulkUpdateInput } from '../models/ApiAdGroupKeywordsControllerBulkUpdateInput'
import {
  ApiAdGroupKeywordsControllerBulkUpdateInputFromJSON,
  ApiAdGroupKeywordsControllerBulkUpdateInputToJSON,
} from '../models/ApiAdGroupKeywordsControllerBulkUpdateInput'
import type { ApiAdGroupKeywordsControllerCreateInput } from '../models/ApiAdGroupKeywordsControllerCreateInput'
import {
  ApiAdGroupKeywordsControllerCreateInputFromJSON,
  ApiAdGroupKeywordsControllerCreateInputToJSON,
} from '../models/ApiAdGroupKeywordsControllerCreateInput'
import type { ApiAdGroupKeywordsControllerSuggestedBidsInput } from '../models/ApiAdGroupKeywordsControllerSuggestedBidsInput'
import {
  ApiAdGroupKeywordsControllerSuggestedBidsInputFromJSON,
  ApiAdGroupKeywordsControllerSuggestedBidsInputToJSON,
} from '../models/ApiAdGroupKeywordsControllerSuggestedBidsInput'
import type { ApiAdGroupKeywordsControllerUpdateInput } from '../models/ApiAdGroupKeywordsControllerUpdateInput'
import {
  ApiAdGroupKeywordsControllerUpdateInputFromJSON,
  ApiAdGroupKeywordsControllerUpdateInputToJSON,
} from '../models/ApiAdGroupKeywordsControllerUpdateInput'
import type { ApiAdGroupKeywordsControllerUploadInput } from '../models/ApiAdGroupKeywordsControllerUploadInput'
import {
  ApiAdGroupKeywordsControllerUploadInputFromJSON,
  ApiAdGroupKeywordsControllerUploadInputToJSON,
} from '../models/ApiAdGroupKeywordsControllerUploadInput'
import type { CountAdGroupKeywordsResponse } from '../models/CountAdGroupKeywordsResponse'
import {
  CountAdGroupKeywordsResponseFromJSON,
  CountAdGroupKeywordsResponseToJSON,
} from '../models/CountAdGroupKeywordsResponse'
import type { ErrorResponseGroup } from '../models/ErrorResponseGroup'
import { ErrorResponseGroupFromJSON, ErrorResponseGroupToJSON } from '../models/ErrorResponseGroup'
import type { GetAdGroupKeywordResponse } from '../models/GetAdGroupKeywordResponse'
import {
  GetAdGroupKeywordResponseFromJSON,
  GetAdGroupKeywordResponseToJSON,
} from '../models/GetAdGroupKeywordResponse'
import type { GetAdGroupKeywordsResponse } from '../models/GetAdGroupKeywordsResponse'
import {
  GetAdGroupKeywordsResponseFromJSON,
  GetAdGroupKeywordsResponseToJSON,
} from '../models/GetAdGroupKeywordsResponse'
import type { GetAdGroupKeywordsSumResponse } from '../models/GetAdGroupKeywordsSumResponse'
import {
  GetAdGroupKeywordsSumResponseFromJSON,
  GetAdGroupKeywordsSumResponseToJSON,
} from '../models/GetAdGroupKeywordsSumResponse'
import type { PostAdGroupKeywordsResponse } from '../models/PostAdGroupKeywordsResponse'
import {
  PostAdGroupKeywordsResponseFromJSON,
  PostAdGroupKeywordsResponseToJSON,
} from '../models/PostAdGroupKeywordsResponse'
import type { PutAdGroupKeywordResponse } from '../models/PutAdGroupKeywordResponse'
import {
  PutAdGroupKeywordResponseFromJSON,
  PutAdGroupKeywordResponseToJSON,
} from '../models/PutAdGroupKeywordResponse'

export interface GetAdGroupKeywordsRequest {
  adGroupId: string
  query?: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  attributionModels?: Array<string>
  includeTotalCount?: boolean
  sortBy?: GetAdGroupKeywordsSortByEnum
  page?: string
  perPage?: number
  defaultKeywordsFilterByBidType?: GetAdGroupKeywordsDefaultKeywordsFilterByBidTypeEnum
  defaultKeywordsFilterByBidStrength?: GetAdGroupKeywordsDefaultKeywordsFilterByBidStrengthEnum
  defaultKeywordsFilterBySource?: GetAdGroupKeywordsDefaultKeywordsFilterBySourceEnum
  includeAutoGenerated?: boolean
  debug?: GetAdGroupKeywordsDebugEnum
  excludeAnalytics?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAdGroupKeywordsCountRequest {
  adGroupId: string
  query?: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  defaultKeywordsFilterByBidType?: GetAdGroupKeywordsCountDefaultKeywordsFilterByBidTypeEnum
  defaultKeywordsFilterByBidStrength?: GetAdGroupKeywordsCountDefaultKeywordsFilterByBidStrengthEnum
  defaultKeywordsFilterBySource?: GetAdGroupKeywordsCountDefaultKeywordsFilterBySourceEnum
  includeAutoGenerated?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAdGroupKeywordsIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
}

export interface GetAdGroupKeywordsSumRequest {
  adGroupId: string
  query?: string
  dateRangeStartDate?: string
  dateRangeEndDate?: string
  attributionModels?: Array<string>
  defaultKeywordsFilterByBidType?: GetAdGroupKeywordsSumDefaultKeywordsFilterByBidTypeEnum
  defaultKeywordsFilterByBidStrength?: GetAdGroupKeywordsSumDefaultKeywordsFilterByBidStrengthEnum
  defaultKeywordsFilterBySource?: GetAdGroupKeywordsSumDefaultKeywordsFilterBySourceEnum
  includeAutoGenerated?: boolean
  xApiKey?: string
  xSecretKey?: string
}

export interface PostAdGroupKeywordsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupKeywordsControllerCreateInput
}

export interface PostAdGroupKeywordsBulkRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupKeywordsControllerBulkCreateInput
}

export interface PostAdGroupKeywordsSuggestedBidsRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupKeywordsControllerSuggestedBidsInput
}

export interface PostAdGroupKeywordsUploadRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupKeywordsControllerUploadInput
}

export interface PutAdGroupKeywordsBulkRequest {
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupKeywordsControllerBulkUpdateInput
}

export interface PutAdGroupKeywordsIdRequest {
  id: string
  xApiKey?: string
  xSecretKey?: string
  body?: ApiAdGroupKeywordsControllerUpdateInput
}

/**
 *
 */
export class AdGroupKeywordsApi extends runtime.BaseAPI {
  /**
   * Use this method to return a list of all override keywords for a specific ad group. You can optionally search for a specific keyword by name and this method returns only the keywords with matching names. You can also send filter criteria as parameters and this method returns only the keywords that match.   This method returns details for each product, including its attributes and analytics on how it’s ad is performing. In addition, it returns some metadata about the list of campaigns it returns, including the number of pages of results which can be used to break the results you receive up.
   * Retrieve a list of keywords
   */
  async getAdGroupKeywordsRaw(
    requestParameters: GetAdGroupKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAdGroupKeywordsResponse>> {
    if (requestParameters.adGroupId === null || requestParameters.adGroupId === undefined) {
      throw new runtime.RequiredError(
        'adGroupId',
        'Required parameter requestParameters.adGroupId was null or undefined when calling getAdGroupKeywords.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.adGroupId !== undefined) {
      queryParameters['ad_group_id'] = requestParameters.adGroupId
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.includeTotalCount !== undefined) {
      queryParameters['include_total_count'] = requestParameters.includeTotalCount
    }

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sort_by'] = requestParameters.sortBy
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage
    }

    if (requestParameters.defaultKeywordsFilterByBidType !== undefined) {
      queryParameters['default_keywords_filter_by[bid_type]'] =
        requestParameters.defaultKeywordsFilterByBidType
    }

    if (requestParameters.defaultKeywordsFilterByBidStrength !== undefined) {
      queryParameters['default_keywords_filter_by[bid_strength]'] =
        requestParameters.defaultKeywordsFilterByBidStrength
    }

    if (requestParameters.defaultKeywordsFilterBySource !== undefined) {
      queryParameters['default_keywords_filter_by[source]'] =
        requestParameters.defaultKeywordsFilterBySource
    }

    if (requestParameters.includeAutoGenerated !== undefined) {
      queryParameters['include_auto_generated'] = requestParameters.includeAutoGenerated
    }

    if (requestParameters.debug !== undefined) {
      queryParameters['debug'] = requestParameters.debug
    }

    if (requestParameters.excludeAnalytics !== undefined) {
      queryParameters['exclude_analytics'] = requestParameters.excludeAnalytics
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAdGroupKeywordsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to return a list of all override keywords for a specific ad group. You can optionally search for a specific keyword by name and this method returns only the keywords with matching names. You can also send filter criteria as parameters and this method returns only the keywords that match.   This method returns details for each product, including its attributes and analytics on how it’s ad is performing. In addition, it returns some metadata about the list of campaigns it returns, including the number of pages of results which can be used to break the results you receive up.
   * Retrieve a list of keywords
   */
  async getAdGroupKeywords(
    requestParameters: GetAdGroupKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAdGroupKeywordsResponse> {
    const response = await this.getAdGroupKeywordsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to return the total count of override keywords for a specific ad group. You can optionally search for a specific keyword by name and this method returns only the count of keywords with matching names. You can also send filter criteria as parameters and this method returns the count of keywords that match.
   * Retrieve the total count of keywords
   */
  async getAdGroupKeywordsCountRaw(
    requestParameters: GetAdGroupKeywordsCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CountAdGroupKeywordsResponse>> {
    if (requestParameters.adGroupId === null || requestParameters.adGroupId === undefined) {
      throw new runtime.RequiredError(
        'adGroupId',
        'Required parameter requestParameters.adGroupId was null or undefined when calling getAdGroupKeywordsCount.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.adGroupId !== undefined) {
      queryParameters['ad_group_id'] = requestParameters.adGroupId
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.defaultKeywordsFilterByBidType !== undefined) {
      queryParameters['default_keywords_filter_by[bid_type]'] =
        requestParameters.defaultKeywordsFilterByBidType
    }

    if (requestParameters.defaultKeywordsFilterByBidStrength !== undefined) {
      queryParameters['default_keywords_filter_by[bid_strength]'] =
        requestParameters.defaultKeywordsFilterByBidStrength
    }

    if (requestParameters.defaultKeywordsFilterBySource !== undefined) {
      queryParameters['default_keywords_filter_by[source]'] =
        requestParameters.defaultKeywordsFilterBySource
    }

    if (requestParameters.includeAutoGenerated !== undefined) {
      queryParameters['include_auto_generated'] = requestParameters.includeAutoGenerated
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords/count`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountAdGroupKeywordsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to return the total count of override keywords for a specific ad group. You can optionally search for a specific keyword by name and this method returns only the count of keywords with matching names. You can also send filter criteria as parameters and this method returns the count of keywords that match.
   * Retrieve the total count of keywords
   */
  async getAdGroupKeywordsCount(
    requestParameters: GetAdGroupKeywordsCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CountAdGroupKeywordsResponse> {
    const response = await this.getAdGroupKeywordsCountRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * This method returns details about a specific keyword in an ad group. You need to specify the id of the keyword — which was returned by POST /ad_groups_keywords when you first created the keyword.
   * Retrieve an ad group keyword
   */
  async getAdGroupKeywordsIdRaw(
    requestParameters: GetAdGroupKeywordsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAdGroupKeywordResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getAdGroupKeywordsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAdGroupKeywordResponseFromJSON(jsonValue)
    )
  }

  /**
   * This method returns details about a specific keyword in an ad group. You need to specify the id of the keyword — which was returned by POST /ad_groups_keywords when you first created the keyword.
   * Retrieve an ad group keyword
   */
  async getAdGroupKeywordsId(
    requestParameters: GetAdGroupKeywordsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAdGroupKeywordResponse> {
    const response = await this.getAdGroupKeywordsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to return a summation of all override keywords for a specific ad group. You can optionally search for a specific keyword by name and this method sums only the keywords with matching names. You can also send filter criteria as parameters and this method sums only the keywords that match.
   * Sums a list of keywords
   */
  async getAdGroupKeywordsSumRaw(
    requestParameters: GetAdGroupKeywordsSumRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GetAdGroupKeywordsSumResponse>> {
    if (requestParameters.adGroupId === null || requestParameters.adGroupId === undefined) {
      throw new runtime.RequiredError(
        'adGroupId',
        'Required parameter requestParameters.adGroupId was null or undefined when calling getAdGroupKeywordsSum.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.adGroupId !== undefined) {
      queryParameters['ad_group_id'] = requestParameters.adGroupId
    }

    if (requestParameters.query !== undefined) {
      queryParameters['query'] = requestParameters.query
    }

    if (requestParameters.dateRangeStartDate !== undefined) {
      queryParameters['date_range[start_date]'] = requestParameters.dateRangeStartDate
    }

    if (requestParameters.dateRangeEndDate !== undefined) {
      queryParameters['date_range[end_date]'] = requestParameters.dateRangeEndDate
    }

    if (requestParameters.attributionModels) {
      queryParameters['attribution_models'] = requestParameters.attributionModels.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.defaultKeywordsFilterByBidType !== undefined) {
      queryParameters['default_keywords_filter_by[bid_type]'] =
        requestParameters.defaultKeywordsFilterByBidType
    }

    if (requestParameters.defaultKeywordsFilterByBidStrength !== undefined) {
      queryParameters['default_keywords_filter_by[bid_strength]'] =
        requestParameters.defaultKeywordsFilterByBidStrength
    }

    if (requestParameters.defaultKeywordsFilterBySource !== undefined) {
      queryParameters['default_keywords_filter_by[source]'] =
        requestParameters.defaultKeywordsFilterBySource
    }

    if (requestParameters.includeAutoGenerated !== undefined) {
      queryParameters['include_auto_generated'] = requestParameters.includeAutoGenerated
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords/sum`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetAdGroupKeywordsSumResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to return a summation of all override keywords for a specific ad group. You can optionally search for a specific keyword by name and this method sums only the keywords with matching names. You can also send filter criteria as parameters and this method sums only the keywords that match.
   * Sums a list of keywords
   */
  async getAdGroupKeywordsSum(
    requestParameters: GetAdGroupKeywordsSumRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GetAdGroupKeywordsSumResponse> {
    const response = await this.getAdGroupKeywordsSumRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to add keywords to an ad group. You must specify the id of the ad group you want to add the keywords to, with a list of keywords. The keywords can be supplied as an array of keyword hash values. Specifically, supply as an array hash values keyword, matching_type, and cpc_bid. Refer to PUT /ad_groups_keywords/{id| for a definition of these fields. Ad groups can contain up to 8000 keywords in total.
   * Add ad group keywords
   */
  async postAdGroupKeywordsRaw(
    requestParameters: PostAdGroupKeywordsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostAdGroupKeywordsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupKeywordsControllerCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PostAdGroupKeywordsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to add keywords to an ad group. You must specify the id of the ad group you want to add the keywords to, with a list of keywords. The keywords can be supplied as an array of keyword hash values. Specifically, supply as an array hash values keyword, matching_type, and cpc_bid. Refer to PUT /ad_groups_keywords/{id| for a definition of these fields. Ad groups can contain up to 8000 keywords in total.
   * Add ad group keywords
   */
  async postAdGroupKeywords(
    requestParameters: PostAdGroupKeywordsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostAdGroupKeywordsResponse> {
    const response = await this.postAdGroupKeywordsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to add up to 50 keywords in bulk. When adding new keywords, you must specify the matching_type, cpc_bid, and corresponding ad group ID. Refer to PUT /ad_groups_keywords/{id} for a definition of these fields. Ad groups can contain up to 8000 keywords in total.
   * Bulk add ad group keywords
   */
  async postAdGroupKeywordsBulkRaw(
    requestParameters: PostAdGroupKeywordsBulkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AdGroupKeywordsBulkResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords/bulk`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupKeywordsControllerBulkCreateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AdGroupKeywordsBulkResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to add up to 50 keywords in bulk. When adding new keywords, you must specify the matching_type, cpc_bid, and corresponding ad group ID. Refer to PUT /ad_groups_keywords/{id} for a definition of these fields. Ad groups can contain up to 8000 keywords in total.
   * Bulk add ad group keywords
   */
  async postAdGroupKeywordsBulk(
    requestParameters: PostAdGroupKeywordsBulkRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AdGroupKeywordsBulkResponse> {
    const response = await this.postAdGroupKeywordsBulkRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   *
   * Get suggested bid for each passed keyword based on the group of product_ids
   */
  async postAdGroupKeywordsSuggestedBidsRaw(
    requestParameters: PostAdGroupKeywordsSuggestedBidsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AdGroupKeywordsSuggestedBidsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords/suggested_bids`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupKeywordsControllerSuggestedBidsInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AdGroupKeywordsSuggestedBidsResponseFromJSON(jsonValue)
    )
  }

  /**
   *
   * Get suggested bid for each passed keyword based on the group of product_ids
   */
  async postAdGroupKeywordsSuggestedBids(
    requestParameters: PostAdGroupKeywordsSuggestedBidsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AdGroupKeywordsSuggestedBidsResponse> {
    const response = await this.postAdGroupKeywordsSuggestedBidsRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * Use this method to upsert keywords to an ad group. You must specify the id of the ad group you want to upsert the keywords to, with a list of keywords. The keywords can be supplied as an array of keyword hash values. Specifically, supply as an array hash values keyword, matching_type, and cpc_bid. Refer to PUT /ad_groups_keywords/{id| for a definition of these fields.
   * Upserts keywords to an ad group
   */
  async postAdGroupKeywordsUploadRaw(
    requestParameters: PostAdGroupKeywordsUploadRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PostAdGroupKeywordsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords/upload`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupKeywordsControllerUploadInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PostAdGroupKeywordsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to upsert keywords to an ad group. You must specify the id of the ad group you want to upsert the keywords to, with a list of keywords. The keywords can be supplied as an array of keyword hash values. Specifically, supply as an array hash values keyword, matching_type, and cpc_bid. Refer to PUT /ad_groups_keywords/{id| for a definition of these fields.
   * Upserts keywords to an ad group
   */
  async postAdGroupKeywordsUpload(
    requestParameters: PostAdGroupKeywordsUploadRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PostAdGroupKeywordsResponse> {
    const response = await this.postAdGroupKeywordsUploadRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Use this method to update up to 50 keywords in bulk. For each updated keyword, you must specify the matching_type, cpc_bid, and corresponding ad group ID. Refer to PUT /ad_groups_keywords/{id} for a definition of these fields.
   * Bulk update ad group keywords
   */
  async putAdGroupKeywordsBulkRaw(
    requestParameters: PutAdGroupKeywordsBulkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AdGroupKeywordsBulkResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords/bulk`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupKeywordsControllerBulkUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AdGroupKeywordsBulkResponseFromJSON(jsonValue)
    )
  }

  /**
   * Use this method to update up to 50 keywords in bulk. For each updated keyword, you must specify the matching_type, cpc_bid, and corresponding ad group ID. Refer to PUT /ad_groups_keywords/{id} for a definition of these fields.
   * Bulk update ad group keywords
   */
  async putAdGroupKeywordsBulk(
    requestParameters: PutAdGroupKeywordsBulkRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AdGroupKeywordsBulkResponse> {
    const response = await this.putAdGroupKeywordsBulkRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * After you have used POST /ad_groups_keywords to add keywords to ad group, use this method to set the CPC bid for each keyword.   Note: To change the bid amount for a default keyword, you need to create a new keyword override with the updated bid amount.
   * Update an ad group keyword
   */
  async putAdGroupKeywordsIdRaw(
    requestParameters: PutAdGroupKeywordsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PutAdGroupKeywordResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling putAdGroupKeywordsId.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xApiKey !== undefined && requestParameters.xApiKey !== null) {
      headerParameters['X-Api-Key'] = String(requestParameters.xApiKey)
    }

    if (requestParameters.xSecretKey !== undefined && requestParameters.xSecretKey !== null) {
      headerParameters['X-Secret-Key'] = String(requestParameters.xSecretKey)
    }

    const response = await this.request(
      {
        path: `/ad_group_keywords/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ApiAdGroupKeywordsControllerUpdateInputToJSON(requestParameters.body),
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PutAdGroupKeywordResponseFromJSON(jsonValue)
    )
  }

  /**
   * After you have used POST /ad_groups_keywords to add keywords to ad group, use this method to set the CPC bid for each keyword.   Note: To change the bid amount for a default keyword, you need to create a new keyword override with the updated bid amount.
   * Update an ad group keyword
   */
  async putAdGroupKeywordsId(
    requestParameters: PutAdGroupKeywordsIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PutAdGroupKeywordResponse> {
    const response = await this.putAdGroupKeywordsIdRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetAdGroupKeywordsSortByEnum = {
  KeywordAsc: 'keyword_asc',
  KeywordDesc: 'keyword_desc',
  EnabledAsc: 'enabled_asc',
  EnabledDesc: 'enabled_desc',
  MatchingTypeAsc: 'matching_type_asc',
  MatchingTypeDesc: 'matching_type_desc',
  BidAsc: 'bid_asc',
  BidDesc: 'bid_desc',
  SpendAsc: 'spend_asc',
  SpendDesc: 'spend_desc',
  AttributedSalesAsc: 'attributed_sales_asc',
  AttributedSalesDesc: 'attributed_sales_desc',
  AttributedQuantitiesAsc: 'attributed_quantities_asc',
  AttributedQuantitiesDesc: 'attributed_quantities_desc',
  RoasAsc: 'roas_asc',
  RoasDesc: 'roas_desc',
  ImpressionsAsc: 'impressions_asc',
  ImpressionsDesc: 'impressions_desc',
  ClicksAsc: 'clicks_asc',
  ClicksDesc: 'clicks_desc',
  CtrAsc: 'ctr_asc',
  CtrDesc: 'ctr_desc',
  CpcAsc: 'cpc_asc',
  CpcDesc: 'cpc_desc',
  CpcBidAsc: 'cpc_bid_asc',
  CpcBidDesc: 'cpc_bid_desc',
  BidTypeAsc: 'bid_type_asc',
  BidTypeDesc: 'bid_type_desc',
  BidStrengthDesc: 'bid_strength_desc',
  BidStrengthAsc: 'bid_strength_asc',
  NtbAttributedSalesAsc: 'ntb_attributed_sales_asc',
  NtbAttributedSalesDesc: 'ntb_attributed_sales_desc',
  PercentNtbAttributedSalesAsc: 'percent_ntb_attributed_sales_asc',
  PercentNtbAttributedSalesDesc: 'percent_ntb_attributed_sales_desc',
  NtbSpendAsc: 'ntb_spend_asc',
  NtbSpendDesc: 'ntb_spend_desc',
  NtbRoasAsc: 'ntb_roas_asc',
  NtbRoasDesc: 'ntb_roas_desc',
} as const
export type GetAdGroupKeywordsSortByEnum =
  typeof GetAdGroupKeywordsSortByEnum[keyof typeof GetAdGroupKeywordsSortByEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsDefaultKeywordsFilterByBidTypeEnum = {
  Default: 'default',
  Override: 'override',
} as const
export type GetAdGroupKeywordsDefaultKeywordsFilterByBidTypeEnum =
  typeof GetAdGroupKeywordsDefaultKeywordsFilterByBidTypeEnum[keyof typeof GetAdGroupKeywordsDefaultKeywordsFilterByBidTypeEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsDefaultKeywordsFilterByBidStrengthEnum = {
  Green: 'green',
  Yellow: 'yellow',
  Red: 'red',
  LowQuality: 'low_quality',
  Unknown: 'unknown',
} as const
export type GetAdGroupKeywordsDefaultKeywordsFilterByBidStrengthEnum =
  typeof GetAdGroupKeywordsDefaultKeywordsFilterByBidStrengthEnum[keyof typeof GetAdGroupKeywordsDefaultKeywordsFilterByBidStrengthEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsDefaultKeywordsFilterBySourceEnum = {
  All: 'all',
  Manual: 'manual',
  Automated: 'automated',
} as const
export type GetAdGroupKeywordsDefaultKeywordsFilterBySourceEnum =
  typeof GetAdGroupKeywordsDefaultKeywordsFilterBySourceEnum[keyof typeof GetAdGroupKeywordsDefaultKeywordsFilterBySourceEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsDebugEnum = {
  True: 'true',
} as const
export type GetAdGroupKeywordsDebugEnum =
  typeof GetAdGroupKeywordsDebugEnum[keyof typeof GetAdGroupKeywordsDebugEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsCountDefaultKeywordsFilterByBidTypeEnum = {
  Default: 'default',
  Override: 'override',
} as const
export type GetAdGroupKeywordsCountDefaultKeywordsFilterByBidTypeEnum =
  typeof GetAdGroupKeywordsCountDefaultKeywordsFilterByBidTypeEnum[keyof typeof GetAdGroupKeywordsCountDefaultKeywordsFilterByBidTypeEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsCountDefaultKeywordsFilterByBidStrengthEnum = {
  Green: 'green',
  Yellow: 'yellow',
  Red: 'red',
  LowQuality: 'low_quality',
  Unknown: 'unknown',
} as const
export type GetAdGroupKeywordsCountDefaultKeywordsFilterByBidStrengthEnum =
  typeof GetAdGroupKeywordsCountDefaultKeywordsFilterByBidStrengthEnum[keyof typeof GetAdGroupKeywordsCountDefaultKeywordsFilterByBidStrengthEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsCountDefaultKeywordsFilterBySourceEnum = {
  All: 'all',
  Manual: 'manual',
  Automated: 'automated',
} as const
export type GetAdGroupKeywordsCountDefaultKeywordsFilterBySourceEnum =
  typeof GetAdGroupKeywordsCountDefaultKeywordsFilterBySourceEnum[keyof typeof GetAdGroupKeywordsCountDefaultKeywordsFilterBySourceEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsSumDefaultKeywordsFilterByBidTypeEnum = {
  Default: 'default',
  Override: 'override',
} as const
export type GetAdGroupKeywordsSumDefaultKeywordsFilterByBidTypeEnum =
  typeof GetAdGroupKeywordsSumDefaultKeywordsFilterByBidTypeEnum[keyof typeof GetAdGroupKeywordsSumDefaultKeywordsFilterByBidTypeEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsSumDefaultKeywordsFilterByBidStrengthEnum = {
  Green: 'green',
  Yellow: 'yellow',
  Red: 'red',
  LowQuality: 'low_quality',
  Unknown: 'unknown',
} as const
export type GetAdGroupKeywordsSumDefaultKeywordsFilterByBidStrengthEnum =
  typeof GetAdGroupKeywordsSumDefaultKeywordsFilterByBidStrengthEnum[keyof typeof GetAdGroupKeywordsSumDefaultKeywordsFilterByBidStrengthEnum]
/**
 * @export
 */
export const GetAdGroupKeywordsSumDefaultKeywordsFilterBySourceEnum = {
  All: 'all',
  Manual: 'manual',
  Automated: 'automated',
} as const
export type GetAdGroupKeywordsSumDefaultKeywordsFilterBySourceEnum =
  typeof GetAdGroupKeywordsSumDefaultKeywordsFilterBySourceEnum[keyof typeof GetAdGroupKeywordsSumDefaultKeywordsFilterBySourceEnum]
