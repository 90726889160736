// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamAssetFieldDecisionsAltText } from './GetDisplayCampaignsIdParamAssetFieldDecisionsAltText'
import {
  GetDisplayCampaignsIdParamAssetFieldDecisionsAltTextFromJSON,
  GetDisplayCampaignsIdParamAssetFieldDecisionsAltTextFromJSONTyped,
  GetDisplayCampaignsIdParamAssetFieldDecisionsAltTextToJSON,
} from './GetDisplayCampaignsIdParamAssetFieldDecisionsAltText'
import type { GetDisplayCampaignsIdParamAssetFieldDecisionsImage } from './GetDisplayCampaignsIdParamAssetFieldDecisionsImage'
import {
  GetDisplayCampaignsIdParamAssetFieldDecisionsImageFromJSON,
  GetDisplayCampaignsIdParamAssetFieldDecisionsImageFromJSONTyped,
  GetDisplayCampaignsIdParamAssetFieldDecisionsImageToJSON,
} from './GetDisplayCampaignsIdParamAssetFieldDecisionsImage'
import type { GetDisplayCampaignsIdParamAssetFieldDecisionsVideo } from './GetDisplayCampaignsIdParamAssetFieldDecisionsVideo'
import {
  GetDisplayCampaignsIdParamAssetFieldDecisionsVideoFromJSON,
  GetDisplayCampaignsIdParamAssetFieldDecisionsVideoFromJSONTyped,
  GetDisplayCampaignsIdParamAssetFieldDecisionsVideoToJSON,
} from './GetDisplayCampaignsIdParamAssetFieldDecisionsVideo'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamAssetFieldDecisions
 */
export interface GetDisplayCampaignsIdParamAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {GetDisplayCampaignsIdParamAssetFieldDecisionsAltText}
   * @memberof GetDisplayCampaignsIdParamAssetFieldDecisions
   */
  altText?: GetDisplayCampaignsIdParamAssetFieldDecisionsAltText
  /**
   *
   * @type {GetDisplayCampaignsIdParamAssetFieldDecisionsImage}
   * @memberof GetDisplayCampaignsIdParamAssetFieldDecisions
   */
  image?: GetDisplayCampaignsIdParamAssetFieldDecisionsImage
  /**
   *
   * @type {GetDisplayCampaignsIdParamAssetFieldDecisionsVideo}
   * @memberof GetDisplayCampaignsIdParamAssetFieldDecisions
   */
  video?: GetDisplayCampaignsIdParamAssetFieldDecisionsVideo
}

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamAssetFieldDecisions interface.
 */
export function instanceOfGetDisplayCampaignsIdParamAssetFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetDisplayCampaignsIdParamAssetFieldDecisionsFromJSON(
  json: any
): GetDisplayCampaignsIdParamAssetFieldDecisions {
  return GetDisplayCampaignsIdParamAssetFieldDecisionsFromJSONTyped(json, false)
}

export function GetDisplayCampaignsIdParamAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : GetDisplayCampaignsIdParamAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : GetDisplayCampaignsIdParamAssetFieldDecisionsImageFromJSON(json['image']),
    video: !exists(json, 'video')
      ? undefined
      : GetDisplayCampaignsIdParamAssetFieldDecisionsVideoFromJSON(json['video']),
  }
}

export function GetDisplayCampaignsIdParamAssetFieldDecisionsToJSON(
  value?: GetDisplayCampaignsIdParamAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    alt_text: GetDisplayCampaignsIdParamAssetFieldDecisionsAltTextToJSON(value.altText),
    image: GetDisplayCampaignsIdParamAssetFieldDecisionsImageToJSON(value.image),
    video: GetDisplayCampaignsIdParamAssetFieldDecisionsVideoToJSON(value.video),
  }
}
