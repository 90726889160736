// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop } from './GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop'
import {
  GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopFromJSON,
  GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopFromJSONTyped,
  GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopToJSON,
} from './GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop'
import type { GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile } from './GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile'
import {
  GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileFromJSON,
  GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileFromJSONTyped,
  GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileToJSON,
} from './GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssets
 */
export interface GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssets {
  /**
   *
   * @type {GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop}
   * @memberof GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssets
   */
  desktop: GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktop
  /**
   *
   * @type {GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile}
   * @memberof GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssets
   */
  mobile: GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobile
}

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssets interface.
 */
export function instanceOfGetDisplayCampaignsIdParamBlockHeroBannerDisplayAssets(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'desktop' in value
  isInstance = isInstance && 'mobile' in value

  return isInstance
}

export function GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsFromJSON(
  json: any
): GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssets {
  return GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsFromJSONTyped(json, false)
}

export function GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    desktop: GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopFromJSON(json['desktop']),
    mobile: GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileFromJSON(json['mobile']),
  }
}

export function GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsToJSON(
  value?: GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    desktop: GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsDesktopToJSON(value.desktop),
    mobile: GetDisplayCampaignsIdParamBlockHeroBannerDisplayAssetsMobileToJSON(value.mobile),
  }
}
