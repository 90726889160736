// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetDisplayCampaignsIdParamAssetFieldDecisions,
  instanceOfGetDisplayCampaignsIdParamAssetFieldDecisions,
  GetDisplayCampaignsIdParamAssetFieldDecisionsFromJSON,
  GetDisplayCampaignsIdParamAssetFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsIdParamAssetFieldDecisionsToJSON,
} from './GetDisplayCampaignsIdParamAssetFieldDecisions'
import {
  GetDisplayCampaignsIdParamBrandPageBlockFieldDecisions,
  instanceOfGetDisplayCampaignsIdParamBrandPageBlockFieldDecisions,
  GetDisplayCampaignsIdParamBrandPageBlockFieldDecisionsFromJSON,
  GetDisplayCampaignsIdParamBrandPageBlockFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsIdParamBrandPageBlockFieldDecisionsToJSON,
} from './GetDisplayCampaignsIdParamBrandPageBlockFieldDecisions'
import {
  GetDisplayCampaignsIdParamBrandPageFieldDecisions,
  instanceOfGetDisplayCampaignsIdParamBrandPageFieldDecisions,
  GetDisplayCampaignsIdParamBrandPageFieldDecisionsFromJSON,
  GetDisplayCampaignsIdParamBrandPageFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsIdParamBrandPageFieldDecisionsToJSON,
} from './GetDisplayCampaignsIdParamBrandPageFieldDecisions'
import {
  GetDisplayCampaignsIdParamCreativeFieldDecisions,
  instanceOfGetDisplayCampaignsIdParamCreativeFieldDecisions,
  GetDisplayCampaignsIdParamCreativeFieldDecisionsFromJSON,
  GetDisplayCampaignsIdParamCreativeFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsIdParamCreativeFieldDecisionsToJSON,
} from './GetDisplayCampaignsIdParamCreativeFieldDecisions'
import {
  GetDisplayCampaignsIdParamDisplayAdGroupFieldDecisions,
  instanceOfGetDisplayCampaignsIdParamDisplayAdGroupFieldDecisions,
  GetDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsFromJSON,
  GetDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsToJSON,
} from './GetDisplayCampaignsIdParamDisplayAdGroupFieldDecisions'
import {
  GetDisplayCampaignsIdParamProductAssetFieldDecisions,
  instanceOfGetDisplayCampaignsIdParamProductAssetFieldDecisions,
  GetDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSON,
  GetDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSONTyped,
  GetDisplayCampaignsIdParamProductAssetFieldDecisionsToJSON,
} from './GetDisplayCampaignsIdParamProductAssetFieldDecisions'

/**
 * @type GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions
 *
 * @export
 */
export type GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions =

    | ({ type: 'ASSET' } & GetDisplayCampaignsIdParamAssetFieldDecisions)
    | ({ type: 'BRAND_PAGE' } & GetDisplayCampaignsIdParamBrandPageFieldDecisions)
    | ({ type: 'BRAND_PAGE_BLOCK' } & GetDisplayCampaignsIdParamBrandPageBlockFieldDecisions)
    | ({ type: 'CREATIVE' } & GetDisplayCampaignsIdParamCreativeFieldDecisions)
    | ({ type: 'DISPLAY_AD_GROUP' } & GetDisplayCampaignsIdParamDisplayAdGroupFieldDecisions)
    | ({ type: 'PRODUCT_ASSET' } & GetDisplayCampaignsIdParamProductAssetFieldDecisions)

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['type']) {
    case 'ASSET':
      return {
        ...GetDisplayCampaignsIdParamAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'ASSET',
      }
    case 'BRAND_PAGE':
      return {
        ...GetDisplayCampaignsIdParamBrandPageFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE',
      }
    case 'BRAND_PAGE_BLOCK':
      return {
        ...GetDisplayCampaignsIdParamBrandPageBlockFieldDecisionsFromJSONTyped(json, true),
        type: 'BRAND_PAGE_BLOCK',
      }
    case 'CREATIVE':
      return {
        ...GetDisplayCampaignsIdParamCreativeFieldDecisionsFromJSONTyped(json, true),
        type: 'CREATIVE',
      }
    case 'DISPLAY_AD_GROUP':
      return {
        ...GetDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsFromJSONTyped(json, true),
        type: 'DISPLAY_AD_GROUP',
      }
    case 'PRODUCT_ASSET':
      return {
        ...GetDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSONTyped(json, true),
        type: 'PRODUCT_ASSET',
      }
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${json['type']}'`
      )
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisionsToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['type']) {
    case 'ASSET':
      return GetDisplayCampaignsIdParamAssetFieldDecisionsToJSON(value)
    case 'BRAND_PAGE':
      return GetDisplayCampaignsIdParamBrandPageFieldDecisionsToJSON(value)
    case 'BRAND_PAGE_BLOCK':
      return GetDisplayCampaignsIdParamBrandPageBlockFieldDecisionsToJSON(value)
    case 'CREATIVE':
      return GetDisplayCampaignsIdParamCreativeFieldDecisionsToJSON(value)
    case 'DISPLAY_AD_GROUP':
      return GetDisplayCampaignsIdParamDisplayAdGroupFieldDecisionsToJSON(value)
    case 'PRODUCT_ASSET':
      return GetDisplayCampaignsIdParamProductAssetFieldDecisionsToJSON(value)
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFieldDecisions exists with 'type=${value['type']}'`
      )
  }
}
