// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues } from './GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues'
import {
  GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON,
  GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSONTyped,
  GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON,
} from './GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues'
import type { GetDisplayCampaignsIdParamCreativeFieldDecisionsTagline } from './GetDisplayCampaignsIdParamCreativeFieldDecisionsTagline'
import {
  GetDisplayCampaignsIdParamCreativeFieldDecisionsTaglineFromJSON,
  GetDisplayCampaignsIdParamCreativeFieldDecisionsTaglineFromJSONTyped,
  GetDisplayCampaignsIdParamCreativeFieldDecisionsTaglineToJSON,
} from './GetDisplayCampaignsIdParamCreativeFieldDecisionsTagline'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamCreativeFieldDecisions
 */
export interface GetDisplayCampaignsIdParamCreativeFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamCreativeFieldDecisions
   */
  type: string
  /**
   *
   * @type {{ [key: string]: GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues; }}
   * @memberof GetDisplayCampaignsIdParamCreativeFieldDecisions
   */
  mainItemGridProducts?: {
    [key: string]: GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValues
  }
  /**
   *
   * @type {GetDisplayCampaignsIdParamCreativeFieldDecisionsTagline}
   * @memberof GetDisplayCampaignsIdParamCreativeFieldDecisions
   */
  tagline?: GetDisplayCampaignsIdParamCreativeFieldDecisionsTagline
}

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamCreativeFieldDecisions interface.
 */
export function instanceOfGetDisplayCampaignsIdParamCreativeFieldDecisions(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetDisplayCampaignsIdParamCreativeFieldDecisionsFromJSON(
  json: any
): GetDisplayCampaignsIdParamCreativeFieldDecisions {
  return GetDisplayCampaignsIdParamCreativeFieldDecisionsFromJSONTyped(json, false)
}

export function GetDisplayCampaignsIdParamCreativeFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamCreativeFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    mainItemGridProducts: !exists(json, 'main_item_grid_products')
      ? undefined
      : mapValues(
          json['main_item_grid_products'],
          GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesFromJSON
        ),
    tagline: !exists(json, 'tagline')
      ? undefined
      : GetDisplayCampaignsIdParamCreativeFieldDecisionsTaglineFromJSON(json['tagline']),
  }
}

export function GetDisplayCampaignsIdParamCreativeFieldDecisionsToJSON(
  value?: GetDisplayCampaignsIdParamCreativeFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    main_item_grid_products:
      value.mainItemGridProducts === undefined
        ? undefined
        : mapValues(
            value.mainItemGridProducts,
            GetDisplayCampaignsIdParamCreativeFieldDecisionsMainItemGridProductsValuesToJSON
          ),
    tagline: GetDisplayCampaignsIdParamCreativeFieldDecisionsTaglineToJSON(value.tagline),
  }
}
