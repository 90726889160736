// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAdGroupProductsResponseDataAttributes
 */
export interface GetAdGroupProductsResponseDataAttributes {
  /**
   * Enabled/Disabled
   * @type {boolean}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  enabled: boolean
  /**
   * Ad Group Product Name
   * @type {string}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  productName: string
  /**
   * Ad Group Product Size
   * @type {string}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  productSize: string
  /**
   * Returns the URL link to the product’s image in Instacart’s catalog.
   * @type {string}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  productImageUrl: string
  /**
   * Returns the URL link to the generic product details page from www.instacart.com
   * @type {string}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  productUrl: string
  /**
   * Returns the brand name
   * @type {string}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  brandName: string
  /**
   * The catalog product id
   * @type {string}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  productId: string
  /**
   * The classified type of the product
   * @type {string}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  productClassifiedType: GetAdGroupProductsResponseDataAttributesProductClassifiedTypeEnum
  /**
   * Ad Group Product UPC
   * @type {string}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  upc: string
  /**
   * Ad Group Product Status
   * @type {string}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  adGroupProductStatus: string
  /**
   * Indicates whether or not the product is mapped to a brand
   * @type {boolean}
   * @memberof GetAdGroupProductsResponseDataAttributes
   */
  isMappedToBrand?: boolean
}

/**
 * @export
 */
export const GetAdGroupProductsResponseDataAttributesProductClassifiedTypeEnum = {
  Universal: 'universal',
  Commodity: 'commodity',
  InHouse: 'in-house',
} as const
export type GetAdGroupProductsResponseDataAttributesProductClassifiedTypeEnum =
  typeof GetAdGroupProductsResponseDataAttributesProductClassifiedTypeEnum[keyof typeof GetAdGroupProductsResponseDataAttributesProductClassifiedTypeEnum]

/**
 * Check if a given object implements the GetAdGroupProductsResponseDataAttributes interface.
 */
export function instanceOfGetAdGroupProductsResponseDataAttributes(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'productName' in value
  isInstance = isInstance && 'productSize' in value
  isInstance = isInstance && 'productImageUrl' in value
  isInstance = isInstance && 'productUrl' in value
  isInstance = isInstance && 'brandName' in value
  isInstance = isInstance && 'productId' in value
  isInstance = isInstance && 'productClassifiedType' in value
  isInstance = isInstance && 'upc' in value
  isInstance = isInstance && 'adGroupProductStatus' in value

  return isInstance
}

export function GetAdGroupProductsResponseDataAttributesFromJSON(
  json: any
): GetAdGroupProductsResponseDataAttributes {
  return GetAdGroupProductsResponseDataAttributesFromJSONTyped(json, false)
}

export function GetAdGroupProductsResponseDataAttributesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAdGroupProductsResponseDataAttributes {
  if (json === undefined || json === null) {
    return json
  }
  return {
    enabled: json['enabled'],
    productName: json['product_name'],
    productSize: json['product_size'],
    productImageUrl: json['product_image_url'],
    productUrl: json['product_url'],
    brandName: json['brand_name'],
    productId: json['product_id'],
    productClassifiedType: json['product_classified_type'],
    upc: json['upc'],
    adGroupProductStatus: json['ad_group_product_status'],
    isMappedToBrand: !exists(json, 'is_mapped_to_brand') ? undefined : json['is_mapped_to_brand'],
  }
}

export function GetAdGroupProductsResponseDataAttributesToJSON(
  value?: GetAdGroupProductsResponseDataAttributes | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    enabled: value.enabled,
    product_name: value.productName,
    product_size: value.productSize,
    product_image_url: value.productImageUrl,
    product_url: value.productUrl,
    brand_name: value.brandName,
    product_id: value.productId,
    product_classified_type: value.productClassifiedType,
    upc: value.upc,
    ad_group_product_status: value.adGroupProductStatus,
    is_mapped_to_brand: value.isMappedToBrand,
  }
}
