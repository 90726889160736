// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamDisplayAssetSnapshot
 */
export interface GetDisplayCampaignsIdParamDisplayAssetSnapshot {
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDisplayAssetSnapshot
   */
  type: string
  /**
   * Asset preview url
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDisplayAssetSnapshot
   */
  previewUrl?: string
  /**
   * Asset alt text
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDisplayAssetSnapshot
   */
  altText?: string
}

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamDisplayAssetSnapshot interface.
 */
export function instanceOfGetDisplayCampaignsIdParamDisplayAssetSnapshot(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function GetDisplayCampaignsIdParamDisplayAssetSnapshotFromJSON(
  json: any
): GetDisplayCampaignsIdParamDisplayAssetSnapshot {
  return GetDisplayCampaignsIdParamDisplayAssetSnapshotFromJSONTyped(json, false)
}

export function GetDisplayCampaignsIdParamDisplayAssetSnapshotFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDisplayAssetSnapshot {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    previewUrl: !exists(json, 'preview_url') ? undefined : json['preview_url'],
    altText: !exists(json, 'alt_text') ? undefined : json['alt_text'],
  }
}

export function GetDisplayCampaignsIdParamDisplayAssetSnapshotToJSON(
  value?: GetDisplayCampaignsIdParamDisplayAssetSnapshot | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    preview_url: value.previewUrl,
    alt_text: value.altText,
  }
}
