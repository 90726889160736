import { css } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import { AccountFeatures } from 'common/accountFeatures'
import { PRODUCT_LIBRARY_CAPABILITIES } from 'common/rolePermissions'
import { WarningIcon } from 'components/atoms'
import FormattedMessage from 'components/FormattedMessage'
import { DefinitionText } from 'components/ids-ads'
import useCapabilities from 'hooks/useCapabilities'

const useStyles = () => {
  const theme = useTheme()

  return {
    container: css({
      display: 'flex',
      color: theme.colors.brandExpressExtraDark,
      fontWeight: 600,
      '& > svg': {
        fill: theme.colors.brandExpressRegular,
        marginRight: spacing.s4,
      },
      '& > p > a': {
        textDecoration: 'underline',
      },
    }),
  }
}

export const MissingBrandHierarchyWarning = () => {
  const { container } = useStyles()
  const hasProductLibraryAccess = useCapabilities({
    capabilities: PRODUCT_LIBRARY_CAPABILITIES,
    featureCapabilities: AccountFeatures.PRODUCT_LIBRARY,
  })

  if (!hasProductLibraryAccess) return null

  return (
    <div css={container}>
      <WarningIcon size={24} />
      <p>
        <FormattedMessage
          id="pages.adGroupEdit.productsTable.missingBrandHierarchy"
          values={{
            tooltip: (
              <DefinitionText
                definitionContent={
                  <FormattedMessage id="pages.adGroupEdit.productsTable.missingBrandHierarchy.tooltip.content" />
                }
              >
                <FormattedMessage id="pages.adGroupEdit.productsTable.missingBrandHierarchy.tooltip.text" />
              </DefinitionText>
            ),
          }}
        />
      </p>
    </div>
  )
}
