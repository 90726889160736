// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppeals } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppeals'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppealsFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppealsFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppealsToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppeals'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates
 */
export interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates {
  /**
   * The entity id
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates
   */
  id: string
  /**
   * The entity version
   * @type {number}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates
   */
  version?: number
  /**
   * The entity type
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates
   */
  type: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum
  /**
   * The state of the editorialization
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates
   */
  state: string
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates
   */
  reviewState?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewState
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates
   */
  entitySnapshot: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshot
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates
   */
  decisionSnapshot: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshot
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppeals}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates
   */
  appeals: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppeals
}

/**
 * @export
 */
export const GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum = {
  Asset: 'ASSET',
  BrandPage: 'BRAND_PAGE',
  BrandPageBlock: 'BRAND_PAGE_BLOCK',
  Creative: 'CREATIVE',
  DisplayAdGroup: 'DISPLAY_AD_GROUP',
  EmailAdGroup: 'EMAIL_AD_GROUP',
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum =
  typeof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum[keyof typeof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesTypeEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates interface.
 */
export function instanceOfGetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'state' in value
  isInstance = isInstance && 'entitySnapshot' in value
  isInstance = isInstance && 'decisionSnapshot' in value
  isInstance = isInstance && 'appeals' in value

  return isInstance
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    version: !exists(json, 'version') ? undefined : json['version'],
    type: json['type'],
    state: json['state'],
    reviewState: !exists(json, 'review_state')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateFromJSON(
          json['review_state']
        ),
    entitySnapshot:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotFromJSON(
        json['entity_snapshot']
      ),
    decisionSnapshot:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotFromJSON(
        json['decision_snapshot']
      ),
    appeals:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppealsFromJSON(
        json['appeals']
      ),
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStates | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    version: value.version,
    type: value.type,
    state: value.state,
    review_state:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesReviewStateToJSON(
        value.reviewState
      ),
    entity_snapshot:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesEntitySnapshotToJSON(
        value.entitySnapshot
      ),
    decision_snapshot:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesDecisionSnapshotToJSON(
        value.decisionSnapshot
      ),
    appeals: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesEditorialStatesAppealsToJSON(
      value.appeals
    ),
  }
}
