// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetDisplayCampaignsIdParamBlockHeroBannerDisplay,
  instanceOfGetDisplayCampaignsIdParamBlockHeroBannerDisplay,
  GetDisplayCampaignsIdParamBlockHeroBannerDisplayFromJSON,
  GetDisplayCampaignsIdParamBlockHeroBannerDisplayFromJSONTyped,
  GetDisplayCampaignsIdParamBlockHeroBannerDisplayToJSON,
} from './GetDisplayCampaignsIdParamBlockHeroBannerDisplay'
import {
  GetDisplayCampaignsIdParamBlockItemGridDisplay,
  instanceOfGetDisplayCampaignsIdParamBlockItemGridDisplay,
  GetDisplayCampaignsIdParamBlockItemGridDisplayFromJSON,
  GetDisplayCampaignsIdParamBlockItemGridDisplayFromJSONTyped,
  GetDisplayCampaignsIdParamBlockItemGridDisplayToJSON,
} from './GetDisplayCampaignsIdParamBlockItemGridDisplay'

/**
 * @type GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks
 *
 * @export
 */
export type GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks =
  | ({ discriminator: 'block_hero_banner.v1' } & GetDisplayCampaignsIdParamBlockHeroBannerDisplay)
  | ({
      discriminator: 'block_item_grid.display.v1'
    } & GetDisplayCampaignsIdParamBlockItemGridDisplay)

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks {
  if (json === undefined || json === null) {
    return json
  }
  switch (json['discriminator']) {
    case 'block_hero_banner.v1':
      return {
        ...GetDisplayCampaignsIdParamBlockHeroBannerDisplayFromJSONTyped(json, true),
        discriminator: 'block_hero_banner.v1',
      }
    case 'block_item_grid.display.v1':
      return {
        ...GetDisplayCampaignsIdParamBlockItemGridDisplayFromJSONTyped(json, true),
        discriminator: 'block_item_grid.display.v1',
      }
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks exists with 'discriminator=${json['discriminator']}'`
      )
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocksToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  switch (value['discriminator']) {
    case 'block_hero_banner.v1':
      return GetDisplayCampaignsIdParamBlockHeroBannerDisplayToJSON(value)
    case 'block_item_grid.display.v1':
      return GetDisplayCampaignsIdParamBlockItemGridDisplayToJSON(value)
    default:
      throw new Error(
        `No variant of GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesBrandPageBlocks exists with 'discriminator=${value['discriminator']}'`
      )
  }
}
