// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData
 */
export interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData {
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData
   */
  linear: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinear
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData
   */
  lastTouch?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouch
}

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData interface.
 */
export function instanceOfGetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'linear' in value

  return isInstance
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    linear:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearFromJSON(
        json['linear']
      ),
    lastTouch: !exists(json, 'last_touch')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchFromJSON(
          json['last_touch']
        ),
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    linear:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLinearToJSON(
        value.linear
      ),
    last_touch:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesAnalyticsAttributionDataLastTouchToJSON(
        value.lastTouch
      ),
  }
}
