// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AuthShowResponseDataAttributesCurrentAccountNextPaymentOption } from './AuthShowResponseDataAttributesCurrentAccountNextPaymentOption'
import {
  AuthShowResponseDataAttributesCurrentAccountNextPaymentOptionFromJSON,
  AuthShowResponseDataAttributesCurrentAccountNextPaymentOptionFromJSONTyped,
  AuthShowResponseDataAttributesCurrentAccountNextPaymentOptionToJSON,
} from './AuthShowResponseDataAttributesCurrentAccountNextPaymentOption'

/**
 *
 * @export
 * @interface AuthShowResponseDataAttributesCurrentAccount
 */
export interface AuthShowResponseDataAttributesCurrentAccount {
  /**
   * Account ID
   * @type {number}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  id: number
  /**
   * Account UUID
   * @type {string}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  uuid: string
  /**
   * Account name
   * @type {string}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  name: string
  /**
   * ISO 3166 three-digit numeric code of the Account country
   * @type {number}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  countryId: number
  /**
   * Account Type
   * @type {string}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  accountType: AuthShowResponseDataAttributesCurrentAccountAccountTypeEnum
  /**
   * Is the account an alcohol account
   * @type {boolean}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  alcohol: boolean
  /**
   * Serving Type: marketplace_only, syndication_network, marketplace_and_syndication(default)
   * @type {string}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  servingType: AuthShowResponseDataAttributesCurrentAccountServingTypeEnum
  /**
   * Is the account an in-house account
   * @type {boolean}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  isInHouse: boolean
  /**
   * What exchange is the account attached too
   * @type {string}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  exchangeName: AuthShowResponseDataAttributesCurrentAccountExchangeNameEnum
  /**
   * The external marketplace that the account belongs to
   * @type {string}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  externalMarketplace?: AuthShowResponseDataAttributesCurrentAccountExternalMarketplaceEnum
  /**
   * Account payment status
   * @type {string}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  paymentStatus: AuthShowResponseDataAttributesCurrentAccountPaymentStatusEnum
  /**
   * Account payment option
   * @type {string}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  activePaymentOption?: AuthShowResponseDataAttributesCurrentAccountActivePaymentOptionEnum
  /**
   *
   * @type {AuthShowResponseDataAttributesCurrentAccountNextPaymentOption}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  nextPaymentOption?: AuthShowResponseDataAttributesCurrentAccountNextPaymentOption
  /**
   * Account creation date
   * @type {string}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  createdAt: string
  /**
   * Whether or not the account requires a purchase order for the advertiser
   * @type {boolean}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  mandatoryAdvertiserPos: boolean
  /**
   * Whether or not the account requires a purchase order for the agency
   * @type {boolean}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  mandatoryAgencyPos: boolean
  /**
   * Whether or not the given user should have access to the Insights Portal on the account
   * @type {boolean}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  canAccessInsightsPortal: boolean
  /**
   * Whether or not the given user should have access to the customer and basket share page in the Insights Portal
   * @type {boolean}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  canAccessCustomerAndBasketShare: boolean
  /**
   * Whether or not the user on the current account can access reservation campaigns reporting
   * @type {boolean}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  canAccessReservationCampaignsReporting: boolean
  /**
   * Agency ID
   * @type {number}
   * @memberof AuthShowResponseDataAttributesCurrentAccount
   */
  agencyId: number
}

/**
 * @export
 */
export const AuthShowResponseDataAttributesCurrentAccountAccountTypeEnum = {
  Default: 'default',
  Retailer: 'retailer',
  RetailerRsd: 'retailer_rsd',
  AdvertiserRtd: 'advertiser_rtd',
} as const
export type AuthShowResponseDataAttributesCurrentAccountAccountTypeEnum =
  typeof AuthShowResponseDataAttributesCurrentAccountAccountTypeEnum[keyof typeof AuthShowResponseDataAttributesCurrentAccountAccountTypeEnum]

/**
 * @export
 */
export const AuthShowResponseDataAttributesCurrentAccountServingTypeEnum = {
  MarketplaceAndSyndication: 'marketplace_and_syndication',
  SyndicationNetwork: 'syndication_network',
  MarketplaceOnly: 'marketplace_only',
} as const
export type AuthShowResponseDataAttributesCurrentAccountServingTypeEnum =
  typeof AuthShowResponseDataAttributesCurrentAccountServingTypeEnum[keyof typeof AuthShowResponseDataAttributesCurrentAccountServingTypeEnum]

/**
 * @export
 */
export const AuthShowResponseDataAttributesCurrentAccountExchangeNameEnum = {
  BristolFarmsUs: 'bristol_farms_us',
  InstacartCa: 'instacart_ca',
  InstacartUs: 'instacart_us',
  PriceChopperUs: 'price_chopper_us',
  ProviUs: 'provi_us',
  SchnucksUs: 'schnucks_us',
  SproutsUs: 'sprouts_us',
  TheFreshMarketUs: 'the_fresh_market_us',
  ThriveMarketUs: 'thrive_market_us',
  TopsMarketUs: 'tops_market_us',
  UdonUs: 'udon_us',
  UdonCa: 'udon_ca',
  WoodmansUs: 'woodmans_us',
} as const
export type AuthShowResponseDataAttributesCurrentAccountExchangeNameEnum =
  typeof AuthShowResponseDataAttributesCurrentAccountExchangeNameEnum[keyof typeof AuthShowResponseDataAttributesCurrentAccountExchangeNameEnum]

/**
 * @export
 */
export const AuthShowResponseDataAttributesCurrentAccountExternalMarketplaceEnum = {
  Provi: 'provi',
} as const
export type AuthShowResponseDataAttributesCurrentAccountExternalMarketplaceEnum =
  typeof AuthShowResponseDataAttributesCurrentAccountExternalMarketplaceEnum[keyof typeof AuthShowResponseDataAttributesCurrentAccountExternalMarketplaceEnum]

/**
 * @export
 */
export const AuthShowResponseDataAttributesCurrentAccountPaymentStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  RequiresPaymentMethod: 'requires_payment_method',
} as const
export type AuthShowResponseDataAttributesCurrentAccountPaymentStatusEnum =
  typeof AuthShowResponseDataAttributesCurrentAccountPaymentStatusEnum[keyof typeof AuthShowResponseDataAttributesCurrentAccountPaymentStatusEnum]

/**
 * @export
 */
export const AuthShowResponseDataAttributesCurrentAccountActivePaymentOptionEnum = {
  CreditCard: 'credit_card',
  InstacartInvoicing: 'instacart_invoicing',
} as const
export type AuthShowResponseDataAttributesCurrentAccountActivePaymentOptionEnum =
  typeof AuthShowResponseDataAttributesCurrentAccountActivePaymentOptionEnum[keyof typeof AuthShowResponseDataAttributesCurrentAccountActivePaymentOptionEnum]

/**
 * Check if a given object implements the AuthShowResponseDataAttributesCurrentAccount interface.
 */
export function instanceOfAuthShowResponseDataAttributesCurrentAccount(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'uuid' in value
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'countryId' in value
  isInstance = isInstance && 'accountType' in value
  isInstance = isInstance && 'alcohol' in value
  isInstance = isInstance && 'servingType' in value
  isInstance = isInstance && 'isInHouse' in value
  isInstance = isInstance && 'exchangeName' in value
  isInstance = isInstance && 'paymentStatus' in value
  isInstance = isInstance && 'createdAt' in value
  isInstance = isInstance && 'mandatoryAdvertiserPos' in value
  isInstance = isInstance && 'mandatoryAgencyPos' in value
  isInstance = isInstance && 'canAccessInsightsPortal' in value
  isInstance = isInstance && 'canAccessCustomerAndBasketShare' in value
  isInstance = isInstance && 'canAccessReservationCampaignsReporting' in value
  isInstance = isInstance && 'agencyId' in value

  return isInstance
}

export function AuthShowResponseDataAttributesCurrentAccountFromJSON(
  json: any
): AuthShowResponseDataAttributesCurrentAccount {
  return AuthShowResponseDataAttributesCurrentAccountFromJSONTyped(json, false)
}

export function AuthShowResponseDataAttributesCurrentAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthShowResponseDataAttributesCurrentAccount {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    uuid: json['uuid'],
    name: json['name'],
    countryId: json['country_id'],
    accountType: json['account_type'],
    alcohol: json['alcohol'],
    servingType: json['serving_type'],
    isInHouse: json['is_in_house'],
    exchangeName: json['exchange_name'],
    externalMarketplace: !exists(json, 'external_marketplace')
      ? undefined
      : json['external_marketplace'],
    paymentStatus: json['payment_status'],
    activePaymentOption: !exists(json, 'active_payment_option')
      ? undefined
      : json['active_payment_option'],
    nextPaymentOption: !exists(json, 'next_payment_option')
      ? undefined
      : AuthShowResponseDataAttributesCurrentAccountNextPaymentOptionFromJSON(
          json['next_payment_option']
        ),
    createdAt: json['created_at'],
    mandatoryAdvertiserPos: json['mandatory_advertiser_pos'],
    mandatoryAgencyPos: json['mandatory_agency_pos'],
    canAccessInsightsPortal: json['can_access_insights_portal'],
    canAccessCustomerAndBasketShare: json['can_access_customer_and_basket_share'],
    canAccessReservationCampaignsReporting: json['can_access_reservation_campaigns_reporting'],
    agencyId: json['agency_id'],
  }
}

export function AuthShowResponseDataAttributesCurrentAccountToJSON(
  value?: AuthShowResponseDataAttributesCurrentAccount | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    uuid: value.uuid,
    name: value.name,
    country_id: value.countryId,
    account_type: value.accountType,
    alcohol: value.alcohol,
    serving_type: value.servingType,
    is_in_house: value.isInHouse,
    exchange_name: value.exchangeName,
    external_marketplace: value.externalMarketplace,
    payment_status: value.paymentStatus,
    active_payment_option: value.activePaymentOption,
    next_payment_option: AuthShowResponseDataAttributesCurrentAccountNextPaymentOptionToJSON(
      value.nextPaymentOption
    ),
    created_at: value.createdAt,
    mandatory_advertiser_pos: value.mandatoryAdvertiserPos,
    mandatory_agency_pos: value.mandatoryAgencyPos,
    can_access_insights_portal: value.canAccessInsightsPortal,
    can_access_customer_and_basket_share: value.canAccessCustomerAndBasketShare,
    can_access_reservation_campaigns_reporting: value.canAccessReservationCampaignsReporting,
    agency_id: value.agencyId,
  }
}
