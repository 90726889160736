/* eslint-disable max-lines */
import {
  GetAdGroupKeywordsCountDefaultKeywordsFilterByBidStrengthEnum,
  GetAdGroupKeywordsCountDefaultKeywordsFilterByBidTypeEnum,
  GetAdGroupKeywordsDefaultKeywordsFilterByBidStrengthEnum,
  GetAdGroupKeywordsDefaultKeywordsFilterByBidTypeEnum,
  GetAdGroupKeywordsDefaultKeywordsFilterBySourceEnum,
} from 'service/openapi/__codegen__/apis/AdGroupKeywordsApi'
import { PostAdGroupProductsRequest } from 'service/openapi/__codegen__/apis/AdGroupProductsApi'
import {
  GetCampaignsSortByEnum,
  GetCampaignsStatusEnum,
  GetCampaignsTypeEnum,
} from 'service/openapi/__codegen__/apis/CampaignsApi'
import { AdGroupSuggestedBidResponse } from 'service/openapi/__codegen__/models/AdGroupSuggestedBidResponse'
import { ApiAdGroupKeywordsControllerCreateInputClientContextEnum } from 'service/openapi/__codegen__/models/ApiAdGroupKeywordsControllerCreateInput'
import { ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum } from 'service/openapi/__codegen__/models/ApiCampaignsControllerCreateInputCampaign'
import { AsyncTaskResponse } from 'service/openapi/__codegen__/models/AsyncTaskResponse'
import { CountAdGroupKeywordsResponseData } from 'service/openapi/__codegen__/models/CountAdGroupKeywordsResponseData'
import { GetAccountProductsResponseData } from 'service/openapi/__codegen__/models/GetAccountProductsResponseData'
import { GetAccountResponseData } from 'service/openapi/__codegen__/models/GetAccountResponseData'
import {
  GetAdGroupKeywordsResponseData,
  GetAdGroupKeywordsResponseDataTypeEnum,
} from 'service/openapi/__codegen__/models/GetAdGroupKeywordsResponseData'
import { GetAdGroupProductsResponseData } from 'service/openapi/__codegen__/models/GetAdGroupProductsResponseData'
import { GetAdGroupProductsResponseDataAttributesProductClassifiedTypeEnum } from 'service/openapi/__codegen__/models/GetAdGroupProductsResponseDataAttributes'
import { GetAdGroupsResponse as OpenAPIGetAdGroupsResponse } from 'service/openapi/__codegen__/models/GetAdGroupsResponse'
import { GetAdGroupsResponseData } from 'service/openapi/__codegen__/models/GetAdGroupsResponseData'
import {
  GetAdGroupsResponseDataAttributesUserTargetingBuyerLicenseEnum as BuyerLicenses,
  GetAdGroupsResponseDataAttributesUserTargetingGeographyEnum as UsStates,
} from 'service/openapi/__codegen__/models/GetAdGroupsResponseDataAttributesUserTargeting'
import { GetCampaignsIdActionsParamDataDefaultBidActions } from 'service/openapi/__codegen__/models/GetCampaignsIdActionsParamDataDefaultBidActions'
import { GetCampaignsResponseDataAttributesSupplementaryStatuses as SupplementaryStatuses } from 'service/openapi/__codegen__/models/GetCampaignsResponseDataAttributesSupplementaryStatuses'
import { GetProductsResponseData } from 'service/openapi/__codegen__/models/GetProductsResponseData'
import {
  KeywordsAnalyticsResponseData,
  KeywordsAnalyticsResponseDataTypeEnum,
} from 'service/openapi/__codegen__/models/KeywordsAnalyticsResponseData'
import {
  KeywordsAnalyticsResponseDataAttributesBidRecs,
  KeywordsAnalyticsResponseDataAttributesBidRecsBidStrengthEnum,
} from 'service/openapi/__codegen__/models/KeywordsAnalyticsResponseDataAttributesBidRecs'
import { PostAdGroupKeywordsParamKeywords } from 'service/openapi/__codegen__/models/PostAdGroupKeywordsParamKeywords'
import { PromotionPricingPolicyFeeResponseDataTypeEnum } from 'service/openapi/__codegen__/models/PromotionPricingPolicyFeeResponseData'
import { PutCampaignsIdParamCampaignAdGroups } from 'service/openapi/__codegen__/models/PutCampaignsIdParamCampaignAdGroups'
import { GetAnalyticsOptions } from 'service/shared'

/* CAMPAIGN */

export interface GetCampaignsOptions {
  type?: GetCampaignsTypeEnum
  page?: number
  enabled?: boolean
  query?: string
  dateRange?: {
    startDate: string
    endDate: string
  }
  sortBy?: GetCampaignsSortByEnum[]
  status?: GetCampaignsStatusEnum
}

export interface Campaign {
  id: string
  type: string
  attributes: {
    name: string
    enabled: boolean
    campaignType: string
    scheduledStartAt: string
    scheduledEndAt: string
    scheduledStartAtDate: string
    scheduledEndAtDate: string
    budget: number
    targetDailyBudget?: number
    budgetType?: string
    goal?: string
    goalFormat?: string
    campaignStatus: string
    billPayer?: string
    paymentType?: string
    purchaseOrder?: string
    memo?: string
    objective?: string
    objectiveCustom?: string
    optimizedBiddingEnabled?: boolean
    optimizedBiddingGoal?: string
    optimizedBiddingParameters?: {
      roasConstraint?: number
    }
    atRiskMeta?: Record<string, unknown>
    supplementaryStatuses?: SupplementaryStatuses
  }
}

export interface GetCampaignsResponse {
  data: Campaign[]
  meta: {
    currentPage: number
    totalPages: number
    totalCount: number
  }
}

export interface GetCampaignResponse {
  data: Campaign
}

export interface CreateCampaignOptions {
  name: string
  objective?: string
  objectiveCustom: string | null
  startsAt: string
  endsAt?: string
  budget: number
  memo?: string
  billPayer?: string
  paymentType?: string
  purchaseOrder?: string
  budgetType: ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum
  targetDailyBudget?: number
  draft?: boolean
}

export interface UpdateCampaignOptions {
  name?: string
  enabled?: boolean
  objective?: string
  objectiveCustom?: string | null
  startsAt?: string
  endsAt?: string
  budget?: number
  memo?: string
  billPayer?: string
  paymentType?: string
  purchaseOrder?: string
  budgetType?: ApiCampaignsControllerCreateInputCampaignBudgetTypeEnum
  targetDailyBudget?: number
  adGroups?: Array<PutCampaignsIdParamCampaignAdGroups>
}

export interface DateRange {
  dateRange?: {
    startDate: string
    endDate: string
  }
}

export type AdGroup = GetAdGroupsResponseData

export interface GetAdGroupsOptions extends DateRange {
  page?: number
  query?: string
  sortBy?: string
  excludeBidRecs?: boolean
}

export type GetAdGroupsResponse = OpenAPIGetAdGroupsResponse

export interface GetAdGroupResponse {
  data: AdGroup
  meta: {
    requestUuid: string
    status: number
  }
}

export interface CreateAdGroupOptions {
  name: string
  defaultBid?: number
  isImpulse?: boolean
  brandedKeywordsOptOutEnabled?: boolean
  geographicTargeting?: Array<UsStates>
  buyerLicenseTargeting?: Array<BuyerLicenses>
}

export interface UpdateAdGroupOptions {
  name?: string
  enabled?: boolean
  defaultBid?: number
  excludeBidRecs?: boolean
  brandedKeywordsOptOutEnabled?: boolean
  geographicTargeting?: Array<UsStates>
  buyerLicenseTargeting?: Array<BuyerLicenses>
}

export interface GetAdGroupKeywordsOptions {
  page?: number
  query?: string
  dateRange?: {
    startDate: string
    endDate: string
  }
  includeAutoGenerated: boolean
  includeTotalCount?: boolean
  sortBy?: string
  perPage?: number
  defaultKeywordsFilterByBidType?: GetAdGroupKeywordsDefaultKeywordsFilterByBidTypeEnum
  defaultKeywordsFilterByBidStrength?: GetAdGroupKeywordsDefaultKeywordsFilterByBidStrengthEnum
  defaultKeywordsFilterBySource?: GetAdGroupKeywordsDefaultKeywordsFilterBySourceEnum
}

export interface CountAdGroupKeywordsOptions {
  query?: string
  dateRange?: {
    startDate: string
    endDate: string
  }
  includeAutoGenerated: boolean
  defaultKeywordsFilterByBidType?: GetAdGroupKeywordsCountDefaultKeywordsFilterByBidTypeEnum
  defaultKeywordsFilterByBidStrength?: GetAdGroupKeywordsCountDefaultKeywordsFilterByBidStrengthEnum
}

export interface GetAdGroupKeywordsResponse {
  data: Array<Keyword>
  meta: {
    currentPage: number
    totalPages: number
    totalCount: number
  }
}

export interface CountAdGroupKeywordsResponse {
  data: KeywordTotalCount
  meta: ResponseMeta
}

export interface GetBidByKeywordsResponse {
  data: KeywordSuggestedBids
  meta: ResponseMeta
}

export interface KeywordSuggestedBids {
  id: string
  type: string
  attributes: {
    suggestedBids: Record<string, number>
    uncompetitiveBids: Record<string, number>
    confidentBids: Record<string, boolean>
    traceInfo?: Record<string, string[]>
  }
}

export interface GetBidByKeywordsOptions {
  productIds: Array<string>
  keywords: Array<string>
}

export interface KeywordEx extends Keyword {
  existing: boolean
  updated: boolean
  suggestedBid?: number
  cpc?: number
  enabled?: boolean
}

// eslint-disable-next-line no-restricted-syntax
export enum PaymentStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  REQUIRES_PAYMENT = 'requires_payment_method',
}

export interface CampaignClientInterface {
  required: this
  getCampaigns: (options?: GetCampaignsOptions) => Promise<GetCampaignsResponse>
  getCampaign: (campaignId: string) => Promise<GetCampaignResponse>
  getCampaignAnalytics: (campaignId: string, options: GetAnalyticsOptions) => Promise<unknown>
  createCampaign: (options: CreateCampaignOptions) => Promise<{ data: Campaign }>
  activateCampaign: (campaignId: string) => Promise<GetCampaignResponse>
  activateCampaignAsync: (campaignId: string) => Promise<AsyncTaskResponse>
  duplicateCampaignAsync: (campaignId: string) => Promise<AsyncTaskResponse>
  updateCampaign: (
    campaignId: string,
    options: UpdateCampaignOptions
  ) => Promise<{ data: Campaign }>
}

export type DefaultBidAction = GetCampaignsIdActionsParamDataDefaultBidActions

/* ACCOUNT */

export type Account = GetAccountResponseData

export interface GetAccountsResponse {
  data: Account[]
}

export interface GetAccountResponse {
  data: Account
}

export interface AccountClientInterface {
  required: this
  getAccounts: () => Promise<GetAccountsResponse>
  getAccount: () => Promise<GetAccountResponse>
  getAccountAnalytics: (accountId: string, options: GetAnalyticsOptions) => Promise<unknown>
  documentURI: (filename: string, accountId?: string) => string
}

export interface AlcoholStatePermissionIds {
  featuredProductIds: string[]
  heroBannerIds: string[]
  couponIds: string[]
}

/*  AdGroupProducts */

export interface GetAdGroupProductsOptions {
  page?: number
  query?: string
  sortBy?: string
  all?: boolean
}

export interface AdGroupProduct {
  id: string
  attributes: {
    enabled: boolean
    productName: string
    productImageUrl: string
    productSize: string
    productClassifiedType: GetAdGroupProductsResponseDataAttributesProductClassifiedTypeEnum
    upc: string
    adGroupProductStatus: string
  }
}

export interface AdGroupProductId {
  id: string
  type: string
  attributes?: {
    adGroupProductStatus?: string
    productEnabled?: boolean
  }
}

export interface GetAdGroupProductsResponse {
  data: AdGroupProduct[]
  meta: {
    currentPage: number
    totalPages: number
    totalCount: number
  }
}

export interface GetAdGroupProductIdsResponse {
  data: Array<AdGroupProductId>
}

export interface UpdateAdGroupProductOptions {
  enabled?: boolean
}

export type AddProductsToAdGroupOptions =
  | {
      upcs: string[] | NonNullable<PostAdGroupProductsRequest['body']>['upcs']
    }
  | {
      productIds: number[] | NonNullable<PostAdGroupProductsRequest['body']>['productIds']
    }

export interface CreateAdGroupProductsResponse {
  data: Array<AdGroupProduct>
}

export type KeywordData = Partial<PostAdGroupKeywordsParamKeywords> & {
  enabled?: boolean
}

export type UpsertKeywordData = Required<Omit<KeywordData, 'cpcBid'>> & {
  cpcBid?: number
}

export interface UpsertAdGroupKeywordOptions {
  keywords: UpsertKeywordData[]
}

export interface UpsertAdGroupKeywordResponse {
  data: Array<Keyword>
  meta: ResponseMeta
}

export interface AddKeywordsToAdGroupOptions {
  keywords: UpsertKeywordData[]
}

export interface UpdateAdGroupKeywordOptions {
  keyword: Partial<UpsertKeywordData>
}

export interface CreateAdGroupKeywordsResponse {
  data: Array<Keyword>
  meta: ResponseMeta
}

export interface UpdateAdGroupKeywordResponse {
  data: Keyword
  meta: ResponseMeta
}

export interface UpsertAdGroupKeywordsResponse {
  data: Array<Keyword>
  meta: {
    duplicates?: string[]
    errors?: {
      [key: string]: Array<string>
    }
  }
}

// Client Interfaces

export interface AdGroupProductsClientInterface {
  required: this
  getAdGroupProducts: (
    adGroupId: string,
    options?: GetAdGroupProductsOptions
  ) => Promise<GetAdGroupProductsResponse>
  getAdGroupProductIds: (
    adGroupId: string,
    options?: GetAdGroupProductIdsOptions
  ) => Promise<GetAdGroupProductIdsResponse>
  addProductsToAdGroup: (
    adGroupId: string,
    options: AddProductsToAdGroupOptions
  ) => Promise<CreateAdGroupProductsResponse>
  updateProduct: (
    productId: string,
    options: UpdateAdGroupProductOptions
  ) => Promise<{ data: AdGroupProduct }>
}

export interface AdGroupClientInterface {
  required: this
  getAdGroups: (campaignId: string, options: GetAdGroupsOptions) => Promise<GetAdGroupsResponse>
  getAdGroup: (adGroupId: string, options?: GetAdGroupOptions) => Promise<GetAdGroupResponse>
  getAdGroupAnalytics: (adGroupId: string, options: GetAnalyticsOptions) => Promise<unknown>
  createAdGroup: (campaignId: string, options: CreateAdGroupOptions) => Promise<GetAdGroupResponse>
  updateAdGroup: (adGroupId: string, options: UpdateAdGroupOptions) => Promise<GetAdGroupResponse>
  getSuggestedBid: (adGroupId: string) => Promise<AdGroupSuggestedBidResponse>
}

export interface GetAdGroupOptions extends DateRange {
  excludeBidRecs?: boolean
}

export interface AdGroupKeywordsClientInterface {
  required: this
  getAdGroupKeywords: (
    adGroupId: string,
    options?: GetAdGroupKeywordsOptions
  ) => Promise<GetAdGroupKeywordsResponse>
  countAdGroupKeywords: (
    adGroupId: string,
    options?: CountAdGroupKeywordsOptions
  ) => Promise<CountAdGroupKeywordsResponse>
  addKeywordsToAdGroup: (
    adGroupId: string,
    options: AddKeywordsToAdGroupOptions,
    clientContext: ApiAdGroupKeywordsControllerCreateInputClientContextEnum
  ) => Promise<CreateAdGroupKeywordsResponse>
  updateAdGroupKeyword: (
    keywordId: string,
    options: UpdateAdGroupKeywordOptions
  ) => Promise<UpdateAdGroupKeywordResponse>
  upsertAdGroupKeywords?: (
    adGroupId: string,
    options: UpsertAdGroupKeywordOptions
  ) => Promise<UpsertAdGroupKeywordsResponse>
  getBidByKeywords: (options: GetBidByKeywordsOptions) => Promise<GetBidByKeywordsResponse>
}

// Required Custom Types:
// The autogenerated client has been known to change the name of the
// type that encapsulates the Response metadata.
// This appears to be due to apipie's logic for constructing a shared type to be used
// by other types having common properties.
// e.g. Previously the Response metadata was in an autogenerated type called GetCampaignResponseMeta
// but now it is in a class called GetDailyBudgetSuggestionResponseMeta
// whereas both contain the same metadata fields.
export interface ResponseMeta {
  status: number
  requestUuid: string
  errors?: ErrorsHash
}

// Cannot define an open hash/object in apipie since it requires
// all properties/params to be named.
// This custom ErrorsHash type can be included in the
// 'attributes' property as well as within the 'meta' property
// of certain responses where they are returned.
export type ErrorsHash = {
  [key: string]: string[]
}

// Provides a wrapper for the PostAdGroupKeywordsResponse
// but extends its attributes.
// There are too many properties in our previous custom Keyword type
// that are not accommodated for in the autogenerated class.
// Examples mainly include properties we add within the ads-web codebase
// that have no corresponding property to an API response. Since
// no annotations have been included for these, they are not autogenerated.

export interface Keyword {
  id: GetAdGroupKeywordsResponseData['id']
  type:
    | GetAdGroupKeywordsResponseData['type']
    | GetAdGroupKeywordsResponseData['type']
    | KeywordsAnalyticsResponseData['type']
    | string // previously: deprecated KeywordAction['type'] === GetAdGroupsIdActionsParamDataActionableKeywords['type']
  attributes: Omit<
    GetAdGroupKeywordsResponseData['attributes'],
    'cpcBid' | 'bidRecs' | 'adGroupKeywordStatus'
  > &
    CustomKeywordAttributes
}

export interface KeywordTotalCount {
  totalCount: CountAdGroupKeywordsResponseData['totalCount']
}

// Provides a more accessible type for the 'type' property of a Keyword
// whose value is an autogenerated Enum.
// Allows classes to import this type only instead of the verbose
// autogenerated Enum.
export type KeywordType =
  | GetAdGroupKeywordsResponseDataTypeEnum
  | KeywordsAnalyticsResponseDataTypeEnum
export const KeywordType = {
  ...GetAdGroupKeywordsResponseDataTypeEnum,
  ...KeywordsAnalyticsResponseDataTypeEnum,
}

// Exposes additional custom attributes that a Keyword type
// only has within the ads-web codebase, or for some reason
// needs to have overrides for in comparison to its corresponding
// autogenerated type (PostAdGroupKeywordsResponseData['attributes'])
export interface CustomKeywordAttributes {
  // -----------------------------------------
  // Overridden Attributes
  // -----------------------------------------

  // Needs to be optional in ads-web
  cpcBid?: number

  // Several different types of bid recs are required
  bidRecs?: KeywordsAnalyticsResponseDataAttributesBidRecs

  // -----------------------------------------
  // Attributes that don't get autogenerated
  // -----------------------------------------
  adGroupKeywordUuid?: string
  bidType?: string
  suggestedBid?: number
  errors?: ErrorsHash
  isConfidentBid?: boolean
  adGroup?: {
    // previously: deprecated GetAdGroupsIdActionsParamDataActionableKeywordsAttributes['adGroup']
    // the only required attribute in the codebase was adGroup?.attributes?.name
    attributes: {
      name: string
    }
  }
}

export interface KnowledgeOwlWidgetClientInterface {
  getConfig: () => Promise<GetKnowledgeOwlWidgetResponse>
}

export interface KnowledgeOwlWidget {
  id: string
  type: string
  attributes: {
    oauthToken: string
    projectId: string
    domain: string
  }
}

export interface GetKnowledgeOwlWidgetResponse {
  data: KnowledgeOwlWidget
  meta: ResponseMeta
}

// An accessible type merging autogenerated bid strength enums
export const BidStrengthEnum = {
  ...KeywordsAnalyticsResponseDataAttributesBidRecsBidStrengthEnum,
  ...GetAdGroupKeywordsDefaultKeywordsFilterByBidStrengthEnum,
}
export type BidStrengthEnum = typeof BidStrengthEnum

export const PromotionPolicyFeeEnum = {
  ...PromotionPricingPolicyFeeResponseDataTypeEnum,
}
export type PromotionPolicyFeeEnum = typeof PromotionPolicyFeeEnum

/* eslint-disable no-restricted-syntax */
export enum SuggestionContext {
  DAILY_BUDGET = 'daily_budget',
  MAXIMUM_IMPRESSION = 'maximum_impression',
}

/* REPORTS */
export interface ReportClientInterface {
  downloadURI: (reportId: string) => string
}

export type AsyncReportType = 'adgroups' | 'campaigns' | 'keywords' | 'top100Keywords' | 'products'

// eslint-disable-next-line no-restricted-syntax
export enum ApplyTypeValues {
  APPLY_SUGGESTED = 'apply_suggested',
  APPLY_ALL_SUGGESTED = 'apply_all_suggested',
  BULK_EDIT = 'bulk_edit',
  BULK_EDIT_APPLY_ALL = 'bulk_edit_apply_all',
  MANUAL_EDIT = 'manual_edit',
  SINGLE_APPLY = 'single_apply',
}

export type ApplyType =
  | ApplyTypeValues.APPLY_SUGGESTED
  | ApplyTypeValues.APPLY_ALL_SUGGESTED
  | ApplyTypeValues.BULK_EDIT
  | ApplyTypeValues.BULK_EDIT_APPLY_ALL
  | ApplyTypeValues.MANUAL_EDIT
  | ApplyTypeValues.SINGLE_APPLY

export enum EventSourceTypeValues {
  ACTION_CENTER = 'action_center',
  ACTION_CENTER_DEFAULT_BID_ACTIONS = 'action_center.default_bid_actions',
  ACTION_CENTER_KEYWORD_ACTIONS = 'action_center.keyword_actions',
  AD_GROUP_KEYWORD_TABLE = 'ad_group_keyword_table',
  AD_GROUP_KEYWORD_FORECAST = 'ad_group_keyword_forecast',
  CPC_BID_OVERVIEW_TAB_AD_GROUPS_TABLE = 'cpc_bid_overview_tab_ad_groups_table',
}

export type EventSourceType =
  | EventSourceTypeValues.ACTION_CENTER
  | EventSourceTypeValues.ACTION_CENTER_DEFAULT_BID_ACTIONS
  | EventSourceTypeValues.ACTION_CENTER_KEYWORD_ACTIONS
  | EventSourceTypeValues.AD_GROUP_KEYWORD_TABLE
  | EventSourceTypeValues.AD_GROUP_KEYWORD_FORECAST
  | EventSourceTypeValues.CPC_BID_OVERVIEW_TAB_AD_GROUPS_TABLE

export enum EventPageTypeValues {
  AD_GROUP_DETAIL = 'ad_group_detail',
  KEYWORD_DETAIL = 'keyword_detail',
  AD_GROUP_RECOMMENDATIONS = 'ad_group_recommendations',
  EDIT_AD_GROUP = 'edit_ad_group',
  NEW_AD_GROUP = 'new_ad_group',
  CAMPAIGN_DETAIL = 'campaign_detail',
  CAMPAIGN_MISSED_OPPORTUNITIES = 'campaign_missed_opportunities',
  CAMPAIGN_RECOMMENDATIONS = 'campaign_recommendations',
  EDIT_CAMPAIGN = 'edit_campaign',
  NEW_CAMPAIGN = 'new_campaign',
  ACCOUNT = 'account',
  CAMPAIGNS_MANAGER = 'campaigns_manager',
  DISPLAY_AD_GROUP_DETAIL = 'display_ad_group_detail',
  EDIT_DISPLAY_AD_GROUP = 'edit_display_ad_group',
  EDIT_EMAIL_AD_GROUP = 'edit_email_ad_group',
  NEW_DISPLAY_AD_GROUP = 'new_display_ad_group',
  NEW_EMAIL_AD_GROUP = 'new_email_ad_group',
  DISPLAY_CAMPAIGNS_MANAGER = 'display_campaigns_manager',
  EMAIL_CAMPAIGNS_MANAGER = 'email_campaigns_manager',
  DISPLAY_CAMPAIGN_DETAIL = 'display_campaign_detail',
  EDIT_DISPLAY_CAMPAIGN = 'edit_display_campaign',
  EDIT_EMAIL_CAMPAIGN = 'edit_email_campaign',
  NEW_DISPLAY_CAMPAIGN = 'new_display_campaign',
  NEW_EMAIL_CAMPAIGN = 'new_email_campaign',
}

export type EventPageType =
  | EventPageTypeValues.AD_GROUP_DETAIL
  | EventPageTypeValues.AD_GROUP_RECOMMENDATIONS
  | EventPageTypeValues.EDIT_AD_GROUP
  | EventPageTypeValues.NEW_AD_GROUP
  | EventPageTypeValues.CAMPAIGN_DETAIL
  | EventPageTypeValues.CAMPAIGN_RECOMMENDATIONS
  | EventPageTypeValues.EDIT_CAMPAIGN
  | EventPageTypeValues.ACCOUNT
  | EventPageTypeValues.CAMPAIGNS_MANAGER
  | EventPageTypeValues.DISPLAY_CAMPAIGNS_MANAGER
  | EventPageTypeValues.EMAIL_CAMPAIGNS_MANAGER
  | EventPageTypeValues.EDIT_DISPLAY_CAMPAIGN
  | EventPageTypeValues.EDIT_EMAIL_CAMPAIGN
  | EventPageTypeValues.NEW_DISPLAY_CAMPAIGN
  | EventPageTypeValues.NEW_EMAIL_CAMPAIGN
  | EventPageTypeValues.EDIT_DISPLAY_AD_GROUP
  | EventPageTypeValues.EDIT_EMAIL_AD_GROUP
  | EventPageTypeValues.NEW_DISPLAY_AD_GROUP
  | EventPageTypeValues.NEW_EMAIL_AD_GROUP
  | EventPageTypeValues.KEYWORD_DETAIL

export enum ThresholdTypeValues {
  RESERVE_FLOOR = 'reserve_floor',
  MAX_BID_THRESHOLD = 'max_bid_threshold',
}

// eslint-disable-next-line no-restricted-syntax
export enum BidTypeValues {
  DEFAULT = 'default',
  KEYWORD = 'keyword',
}

// eslint-disable-next-line no-restricted-syntax
export enum AlcoholAdTypeValues {
  FEATURED_PRODUCT = 'featured_product',
  HERO_BANNER = 'hero_banner',
  COUPON = 'coupon',
}

// eslint-disable-next-line no-restricted-syntax
export enum EventMetricTypeValues {
  LOST_IMPRESSIONS = 'lost_impressions',
  LOST_SALES = 'lost_sales',
  BUDGET_EXHAUSTION_TIME = 'budget_exhaustion_time',
}

export enum CampaignMissedOpportunitiesEventMetricTypeValues {
  ESTIMATED_LOST_IMPRESSIONS = 'estimated_lost_impressions',
  ESTIMATED_LOST_SALES = 'estimed_lost_sales',
  MISSED_AUCTION_PARTICIPATION_RATE = 'missed_auction_participation_rate',
}

export type AlcoholAdType = AlcoholAdTypeValues.FEATURED_PRODUCT | AlcoholAdTypeValues.HERO_BANNER

export type Product = GetAdGroupProductsResponseData | GetProductsResponseData

export type AccountProduct = GetAccountProductsResponseData

export type ProductEx = Product & {
  existing?: boolean
  enabled?: boolean
  adGroupProductId?: string
  updated?: boolean
  productUpdateSubmitted?: boolean
}

// TODO: BidLandscapeDataKey and BidLandscapeData should be removed once bid_landscape is removed from
// the products#suggested_bid endpoint. Bid landscape data should be fetched directly from
// the bid_landscape endpoints.
export type BidLandscapeDataKey = 'capture_25' | 'capture_50' | 'capture_75' | 'capture_90'

export type BidLandscapeData = {
  [key in BidLandscapeDataKey]: number
}

export type BidLandscapeV2Data = {
  predictedImpressionsRange?: number[]
  predictedClicksRange: number[]
  predictedAttributedSalesRange: number[]
  predictedSpendRange: number[]
  predictedRoasRange: number[]
  suggestedBid: number
}

export interface GetAdGroupProductIdsOptions {
  includeAdGroupProductStatus?: boolean
  includeProductEnabled?: boolean
}

// eslint-disable-next-line no-restricted-syntax
export enum MetricsValues {
  ESTIMATED_LOST_IMPRESSIONS = 'estimatedLostImpressions',
  BUDGET_EXHAUSTION_TIME = 'budgetExhaustionTime',
  ESTIMATED_LOST_SALES = 'estimatedLostSales',
  MISSED_AUCTION_PARTICIPATION_RATE = 'missedAuctionParticipationRate',
}

export type CampaignMissedOpportunitiesMetrics =
  | MetricsValues.ESTIMATED_LOST_IMPRESSIONS
  | MetricsValues.ESTIMATED_LOST_SALES
  | MetricsValues.MISSED_AUCTION_PARTICIPATION_RATE

export enum EventActionCenterTabValues {
  BUDGET = 'budget',
  DEFAULT_BID_ACTIONS = 'default_bid_actions',
  KEYWORD_ACTIONS = 'keyword_actions',
  OPTIMIZED_BIDDING_MIN_ROAS = 'optimized_bidding_min_roas',
}

export enum AutoBiddingGoalValues {
  MAX_SALES = 'max_sales',
  ACQUIRE_NTB = 'acquire_ntb',
}

// eslint-disable-next-line no-restricted-syntax
export enum BudgetTypeValues {
  DAILY = 'daily_budget',
  PACED_SPENDING = 'paced_spending',
  MAX_IMPRESSIONS = 'max_impressions',
}

export type BudgetType =
  | BudgetTypeValues.DAILY
  | BudgetTypeValues.PACED_SPENDING
  | BudgetTypeValues.MAX_IMPRESSIONS
