// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
 */
export interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions {
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
   */
  mainItemGrid?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGrid
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
   */
  mainProductGroupGrid?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGrid
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
   */
  mainCollectionGrid?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGrid
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions
   */
  bannerClick?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClick
}

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions interface.
 */
export function instanceOfGetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mainItemGrid: !exists(json, 'main_item_grid')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridFromJSON(
          json['main_item_grid']
        ),
    mainProductGroupGrid: !exists(json, 'main_product_group_grid')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridFromJSON(
          json['main_product_group_grid']
        ),
    mainCollectionGrid: !exists(json, 'main_collection_grid')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridFromJSON(
          json['main_collection_grid']
        ),
    bannerClick: !exists(json, 'banner_click')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickFromJSON(
          json['banner_click']
        ),
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    main_item_grid:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainItemGridToJSON(
        value.mainItemGrid
      ),
    main_product_group_grid:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainProductGroupGridToJSON(
        value.mainProductGroupGrid
      ),
    main_collection_grid:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsMainCollectionGridToJSON(
        value.mainCollectionGrid
      ),
    banner_click:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeActionsBannerClickToJSON(
        value.bannerClick
      ),
  }
}
