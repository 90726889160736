// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamDataAttributesAdGroups
 */
export interface GetDisplayCampaignsIdParamDataAttributesAdGroups {
  /**
   * DisplayAdGroup ID
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroups
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroups
   */
  type: GetDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroups
   */
  attributes: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributes
}

/**
 * @export
 */
export const GetDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum = {
  DisplayAdGroup: 'display_ad_group',
} as const
export type GetDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum =
  typeof GetDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum[keyof typeof GetDisplayCampaignsIdParamDataAttributesAdGroupsTypeEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamDataAttributesAdGroups interface.
 */
export function instanceOfGetDisplayCampaignsIdParamDataAttributesAdGroups(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'attributes' in value

  return isInstance
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroups {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsFromJSONTyped(json, false)
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroups {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    attributes: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesFromJSON(
      json['attributes']
    ),
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroups | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    attributes: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesToJSON(value.attributes),
  }
}
