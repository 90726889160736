import { css, SerializedStyles } from '@emotion/react'
import { spacing, useTheme } from '@instacart/ids-core'
import { ComponentPropsWithoutRef } from 'react'
import toPx from 'common/toPx'
import { useScrollDirection, ScrollDirection } from 'hooks/useScrollDirection'

export interface ModalContentProps extends ComponentPropsWithoutRef<'div'> {
  allowScroll?: boolean
  testId?: string
  border?: boolean
  customStyles?: SerializedStyles
}

const useStyles = (scrollDirection?: ScrollDirection, allowScroll?: boolean) => {
  const theme = useTheme()

  const showBorder = (show: boolean) => {
    return `solid 1px ${show ? theme.colors.systemGrayscale20 : 'transparent'}`
  }

  return css({
    flex: '1',
    overflowY: allowScroll ? 'auto' : 'inherit',
    ...theme.typography.bodyMedium2,
    margin: toPx`0 -${spacing.s24} ${spacing.s24}`,
    padding: toPx`0 ${spacing.s24}`,
    borderTop: showBorder(scrollDirection === ScrollDirection.UP),
    borderBottom: showBorder(scrollDirection === ScrollDirection.DOWN),
  })
}

export const ModalContent = ({
  children,
  allowScroll = false,
  testId,
  customStyles,
}: ModalContentProps) => {
  const [scrollDirection, scrollProps] = useScrollDirection()
  const baseStyles = useStyles(scrollDirection as ScrollDirection, allowScroll)
  const styles = css([baseStyles, customStyles])

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <div {...scrollProps} css={styles} data-testid={testId}>
      {children}
    </div>
  )
}
