// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ApiDisplayCampaignsControllerUpdateInputCampaign
 */
export interface ApiDisplayCampaignsControllerUpdateInputCampaign {
  /**
   * A descriptive name for the campaign.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  name: string
  /**
   * Set to true to activate the campaign. You will bid according to the details of the ad groups contained within this campaign. Set to false and the campaign is not active.
   * @type {boolean}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  enabled: boolean
  /**
   * The total budget for the ads campaign, expressed in dollars. When the campaign has spent this budget, it automatically ends.
   * @type {number}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  budget: number
  /**
   * Send paced, asap for this field.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  pacingType: ApiDisplayCampaignsControllerUpdateInputCampaignPacingTypeEnum
  /**
   * The campaign’s start date in the ‘YYYY-MM-DD’ format. Campaigns start midnight, PST, on the specified date. You must also set enabled to true.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  startsAt: string
  /**
   * The campaign’s effective end date in the ‘YYYY-MM-DD’ format. Campaigns end at midnight, PST, on the specified date. Omit to automatically stop the campaign when the campaign’s budget has been spent.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  endsAt?: string
  /**
   * Override for default account-level bill payer. Agency, advertiser or retailer to be billed for the campaign.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  billPayer?: ApiDisplayCampaignsControllerUpdateInputCampaignBillPayerEnum
  /**
   * Send consideration, awareness, sales for this field.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  objective?: ApiDisplayCampaignsControllerUpdateInputCampaignObjectiveEnum
  /**
   * If you want to include a memo line on the invoices Instacart generates for this ad campaign, specify it in this field.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  memo?: string
  /**
   * If you want to include a purchase order number on the invoices Instacart generates for this ad campaign, specify it in this field, ensuring the length does not exceed 50 characters.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  purchaseOrder?: string
  /**
   * For reference only. Specifies how you want Instacart to bill you for the campaign. If you want Instacart to bill on a per purchase order, specify "purchase_order". If you want us to bill to your account, specify "account".
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  paymentType?: ApiDisplayCampaignsControllerUpdateInputCampaignPaymentTypeEnum
  /**
   * The campaign’s goal.
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  goal?: string
  /**
   * The campaign’s goal format
   * @type {string}
   * @memberof ApiDisplayCampaignsControllerUpdateInputCampaign
   */
  goalFormat?: string
}

/**
 * @export
 */
export const ApiDisplayCampaignsControllerUpdateInputCampaignPacingTypeEnum = {
  Paced: 'paced',
  Asap: 'asap',
} as const
export type ApiDisplayCampaignsControllerUpdateInputCampaignPacingTypeEnum =
  typeof ApiDisplayCampaignsControllerUpdateInputCampaignPacingTypeEnum[keyof typeof ApiDisplayCampaignsControllerUpdateInputCampaignPacingTypeEnum]

/**
 * @export
 */
export const ApiDisplayCampaignsControllerUpdateInputCampaignBillPayerEnum = {
  Agency: 'agency',
  Advertiser: 'advertiser',
  Retailer: 'retailer',
} as const
export type ApiDisplayCampaignsControllerUpdateInputCampaignBillPayerEnum =
  typeof ApiDisplayCampaignsControllerUpdateInputCampaignBillPayerEnum[keyof typeof ApiDisplayCampaignsControllerUpdateInputCampaignBillPayerEnum]

/**
 * @export
 */
export const ApiDisplayCampaignsControllerUpdateInputCampaignObjectiveEnum = {
  Consideration: 'consideration',
  Awareness: 'awareness',
  Sales: 'sales',
} as const
export type ApiDisplayCampaignsControllerUpdateInputCampaignObjectiveEnum =
  typeof ApiDisplayCampaignsControllerUpdateInputCampaignObjectiveEnum[keyof typeof ApiDisplayCampaignsControllerUpdateInputCampaignObjectiveEnum]

/**
 * @export
 */
export const ApiDisplayCampaignsControllerUpdateInputCampaignPaymentTypeEnum = {
  Account: 'account',
  PurchaseOrder: 'purchase_order',
} as const
export type ApiDisplayCampaignsControllerUpdateInputCampaignPaymentTypeEnum =
  typeof ApiDisplayCampaignsControllerUpdateInputCampaignPaymentTypeEnum[keyof typeof ApiDisplayCampaignsControllerUpdateInputCampaignPaymentTypeEnum]

/**
 * Check if a given object implements the ApiDisplayCampaignsControllerUpdateInputCampaign interface.
 */
export function instanceOfApiDisplayCampaignsControllerUpdateInputCampaign(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'enabled' in value
  isInstance = isInstance && 'budget' in value
  isInstance = isInstance && 'pacingType' in value
  isInstance = isInstance && 'startsAt' in value

  return isInstance
}

export function ApiDisplayCampaignsControllerUpdateInputCampaignFromJSON(
  json: any
): ApiDisplayCampaignsControllerUpdateInputCampaign {
  return ApiDisplayCampaignsControllerUpdateInputCampaignFromJSONTyped(json, false)
}

export function ApiDisplayCampaignsControllerUpdateInputCampaignFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiDisplayCampaignsControllerUpdateInputCampaign {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    enabled: json['enabled'],
    budget: json['budget'],
    pacingType: json['pacing_type'],
    startsAt: json['starts_at'],
    endsAt: !exists(json, 'ends_at') ? undefined : json['ends_at'],
    billPayer: !exists(json, 'bill_payer') ? undefined : json['bill_payer'],
    objective: !exists(json, 'objective') ? undefined : json['objective'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    purchaseOrder: !exists(json, 'purchase_order') ? undefined : json['purchase_order'],
    paymentType: !exists(json, 'payment_type') ? undefined : json['payment_type'],
    goal: !exists(json, 'goal') ? undefined : json['goal'],
    goalFormat: !exists(json, 'goal_format') ? undefined : json['goal_format'],
  }
}

export function ApiDisplayCampaignsControllerUpdateInputCampaignToJSON(
  value?: ApiDisplayCampaignsControllerUpdateInputCampaign | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    enabled: value.enabled,
    budget: value.budget,
    pacing_type: value.pacingType,
    starts_at: value.startsAt,
    ends_at: value.endsAt,
    bill_payer: value.billPayer,
    objective: value.objective,
    memo: value.memo,
    purchase_order: value.purchaseOrder,
    payment_type: value.paymentType,
    goal: value.goal,
    goal_format: value.goalFormat,
  }
}
