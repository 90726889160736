// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltText } from './GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltText'
import {
  GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextFromJSON,
  GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextFromJSONTyped,
  GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextToJSON,
} from './GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltText'
import type { GetDisplayCampaignsIdParamProductAssetFieldDecisionsImage } from './GetDisplayCampaignsIdParamProductAssetFieldDecisionsImage'
import {
  GetDisplayCampaignsIdParamProductAssetFieldDecisionsImageFromJSON,
  GetDisplayCampaignsIdParamProductAssetFieldDecisionsImageFromJSONTyped,
  GetDisplayCampaignsIdParamProductAssetFieldDecisionsImageToJSON,
} from './GetDisplayCampaignsIdParamProductAssetFieldDecisionsImage'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamProductAssetFieldDecisions
 */
export interface GetDisplayCampaignsIdParamProductAssetFieldDecisions {
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamProductAssetFieldDecisions
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof GetDisplayCampaignsIdParamProductAssetFieldDecisions
   */
  discriminator: GetDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum
  /**
   *
   * @type {GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltText}
   * @memberof GetDisplayCampaignsIdParamProductAssetFieldDecisions
   */
  altText?: GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltText
  /**
   *
   * @type {GetDisplayCampaignsIdParamProductAssetFieldDecisionsImage}
   * @memberof GetDisplayCampaignsIdParamProductAssetFieldDecisions
   */
  image?: GetDisplayCampaignsIdParamProductAssetFieldDecisionsImage
}

/**
 * @export
 */
export const GetDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum = {
  ProductAsset: 'PRODUCT_ASSET',
} as const
export type GetDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum =
  typeof GetDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum[keyof typeof GetDisplayCampaignsIdParamProductAssetFieldDecisionsDiscriminatorEnum]

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamProductAssetFieldDecisions interface.
 */
export function instanceOfGetDisplayCampaignsIdParamProductAssetFieldDecisions(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'discriminator' in value

  return isInstance
}

export function GetDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSON(
  json: any
): GetDisplayCampaignsIdParamProductAssetFieldDecisions {
  return GetDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSONTyped(json, false)
}

export function GetDisplayCampaignsIdParamProductAssetFieldDecisionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamProductAssetFieldDecisions {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: json['type'],
    discriminator: json['discriminator'],
    altText: !exists(json, 'alt_text')
      ? undefined
      : GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextFromJSON(json['alt_text']),
    image: !exists(json, 'image')
      ? undefined
      : GetDisplayCampaignsIdParamProductAssetFieldDecisionsImageFromJSON(json['image']),
  }
}

export function GetDisplayCampaignsIdParamProductAssetFieldDecisionsToJSON(
  value?: GetDisplayCampaignsIdParamProductAssetFieldDecisions | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: value.type,
    discriminator: value.discriminator,
    alt_text: GetDisplayCampaignsIdParamProductAssetFieldDecisionsAltTextToJSON(value.altText),
    image: GetDisplayCampaignsIdParamProductAssetFieldDecisionsImageToJSON(value.image),
  }
}
