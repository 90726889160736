import { css } from '@emotion/react'
import { spacing } from '@instacart/ids-core'
import { useEffect, useState } from 'react'
import { useIntl } from 'common'
import Analytics from 'common/analytics/Analytics'
import FormattedMessage from 'components/FormattedMessage'
import { ModalHeader, ModalProps } from 'components/ids-ads'
import { DEFAULT_MODAL_WIDTH } from 'components/ids-ads/organisms/modals/utils'
import LoginForm from 'components/Login/components/LoginForm'
import { CREDENTIALS, LoginStep, MULTI_FACTOR_AUTH } from 'components/Login/utils/types'
import Modal from 'components/organisms/Modal'
import { GenericMessageDescriptor, MessageIdType } from 'locales/types'

const useStyles = () => {
  return {
    content: css({ marginTop: -spacing.s16, fontSize: '14px' }),
  }
}

const LOGIN_MODAL_TITLES: Record<LoginStep, GenericMessageDescriptor> = {
  [CREDENTIALS]: 'common.logIn',
  [MULTI_FACTOR_AUTH]: 'pages.multiFactorAuth.title.login',
}

export interface LoginModalProps extends Omit<ModalProps, 'children' | 'title'> {
  redirectUrl?: string
  redirectToWindow?: boolean
  subHeadingId?: MessageIdType
}

export default function LoginModal({
  modalState,
  redirectUrl,
  redirectToWindow,
  subHeadingId,
  onClose = modalState.hide,
  hideCloseIcon,
  ...modalProps
}: LoginModalProps) {
  const { genericFormatMessage } = useIntl()
  const styles = useStyles()
  const [loginStep, setLoginStep] = useState<LoginStep>(CREDENTIALS)

  // resetting state when modal is hidden
  useEffect(() => {
    if (modalState.visible) {
      Analytics.track('login.login_open')
    }
    setLoginStep(CREDENTIALS)
  }, [modalState])

  return (
    <Modal
      {...modalProps}
      show={modalState.visible}
      onClose={onClose}
      testId="login-modal"
      width={DEFAULT_MODAL_WIDTH}
    >
      <div css={styles.content}>
        <ModalHeader onClose={onClose} hideCloseIcon={hideCloseIcon}>
          {genericFormatMessage(LOGIN_MODAL_TITLES[loginStep])}
        </ModalHeader>
        {subHeadingId && <FormattedMessage id={subHeadingId} />}
        <LoginForm
          redirectUrl={redirectUrl}
          redirectToWindow={redirectToWindow}
          loginStep={loginStep}
          setLoginStep={setLoginStep}
        />
      </div>
    </Modal>
  )
}
