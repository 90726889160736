import { Global, css } from '@emotion/react'
import { FontLoader, INSTACART_SANS_FONT_FAMILY } from '@instacart/ids-core'
import IcIconsWoff from '../../../node_modules/ic-snacks/dist/fonts/ic-icons.woff'

const IDS_COLORS = {
  systemGrayscale00: '#FFFFFF',
  systemGrayscale70: '#343538',
  brandPrimaryRegular: '#108910',
}

const globalStyles = css`
  // starting reset
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1.2;
  }
  ol,
  ul {
    list-style: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  ins,
  del {
    text-decoration: none;
  }
  // starting base app styles
  html,
  body,
  #__ads_root {
    height: 100%;
  }
  body {
    background: ${IDS_COLORS.systemGrayscale00};
    color: ${IDS_COLORS.systemGrayscale70};
    cursor: default;
    direction: ltr;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: '${INSTACART_SANS_FONT_FAMILY}', 'Helvetica Neue', Helvetica, sans-serif;
  }
  a,
  a:visited {
    text-decoration: none;
    color: ${IDS_COLORS.brandPrimaryRegular};
  }
  a:hover {
    color: ${IDS_COLORS.brandPrimaryRegular};
  }
  // Snacks applies type="button" to anchor elements when you supply href to a Button. Though it's not semantically
  // correct, it allows us to target these "buttons" to fix issues with line height
  button,
  a[type='button'] {
    line-height: 1;
  }

  // This fixes issues with Snacks buttons that have an href property in Safari. Without this property Safari
  // will override the element's background color, border color, and border radius
  a[type='button'] {
    -webkit-appearance: none;
  }

  .actions-wrap {
    display: flex;
    flex-direction: row;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }
  }

  .StripeElement {
    padding: 8px 12px 10px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px #c7c8cd inset;
    transition: box-shadow 100ms ease-in-out;
  }

  .StripeElement--focus {
    box-shadow: 0 0 0 2px ${IDS_COLORS.systemGrayscale70} inset;
  }

  .StripeElement--invalid {
    background-color: #fff6f6;
    box-shadow: 0 0 0 1px #de3534 inset;
  }

  .StripeElement--invalid.StripeElement--focus {
    box-shadow: 0 0 0 2px #de3534 inset;
  }

  .password-form {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .form {
      align-items: center;
      background: #fff;
      border-radius: 3px;
      box-shadow: 0 1px 2px 0 rgba(170, 170, 170, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 64px 140px;
      width: 300px;

      h1 {
        display: block;
        font-size: 28px;
        margin: 10px 0 26px;
      }
      h2 {
        color: #939291;
        display: block;
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 10px;
      }
      h3 {
        font-size: 16px;
        font-weight: 600;
      }
      .input {
        display: block;
        margin-bottom: 10px;
        width: 100%;
      }

      .remembered {
        color: ${IDS_COLORS.brandPrimaryRegular};
        font-size: 14px;
        margin-top: 24px;
      }
      .remembered a {
        color: #43b02a;
        font-weight: normal;
      }

      .successMessage h1 {
        display: block;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
`

export const BaseStyles = () => (
  <>
    <Global styles={globalStyles} />
    <FontLoader />
    <FontLoader
      fontFamily="ic-icons"
      variants={[
        {
          fontWeight: 'normal',
          fontStyle: 'normal',
          src: [{ url: IcIconsWoff, format: 'woff' }],
        },
      ]}
    />
  </>
)
