// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Ads API (params in:body)
 * Ads API allows you to interact directly with the Instacart Ads platform, programmatically managing accounts and advertising campaigns. The API accepts form-encoded requests and returns JSON-encoded response.  We provide OAuth2 for authentication. API requests must supply an Authorization header with a token header as follows: \"Authorization\": \"Bearer <access_token>\".  Using *X-Api-Key* and *X-Secret-Key* request headers for authentication to be deprecated.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb'
import type { GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo } from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo'
import {
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSON,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSONTyped,
  GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoToJSON,
} from './GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo'

/**
 *
 * @export
 * @interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
 */
export interface GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets {
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  topImageWeb?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWeb
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  imageWeb?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWeb
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  imageMobile?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobile
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  topImageMobile?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobile
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  squareImageMobile?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobile
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  heroImage?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImage
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  brandLogo?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogo
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  video?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideo
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  thumbnail?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnail
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  uvcImageWeb?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWeb
  /**
   *
   * @type {GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile}
   * @memberof GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets
   */
  uvcImageMobile?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobile
}

/**
 * Check if a given object implements the GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets interface.
 */
export function instanceOfGetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets(
  value: object
): boolean {
  let isInstance = true

  return isInstance
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSON(
  json: any
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets {
  return GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped(
    json,
    false
  )
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets {
  if (json === undefined || json === null) {
    return json
  }
  return {
    topImageWeb: !exists(json, 'top_image_web')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebFromJSON(
          json['top_image_web']
        ),
    imageWeb: !exists(json, 'image_web')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebFromJSON(
          json['image_web']
        ),
    imageMobile: !exists(json, 'image_mobile')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileFromJSON(
          json['image_mobile']
        ),
    topImageMobile: !exists(json, 'top_image_mobile')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileFromJSON(
          json['top_image_mobile']
        ),
    squareImageMobile: !exists(json, 'square_image_mobile')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileFromJSON(
          json['square_image_mobile']
        ),
    heroImage: !exists(json, 'hero_image')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageFromJSON(
          json['hero_image']
        ),
    brandLogo: !exists(json, 'brand_logo')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoFromJSON(
          json['brand_logo']
        ),
    video: !exists(json, 'video')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoFromJSON(
          json['video']
        ),
    thumbnail: !exists(json, 'thumbnail')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailFromJSON(
          json['thumbnail']
        ),
    uvcImageWeb: !exists(json, 'uvc_image_web')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebFromJSON(
          json['uvc_image_web']
        ),
    uvcImageMobile: !exists(json, 'uvc_image_mobile')
      ? undefined
      : GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileFromJSON(
          json['uvc_image_mobile']
        ),
  }
}

export function GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsToJSON(
  value?: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssets | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    top_image_web:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageWebToJSON(
        value.topImageWeb
      ),
    image_web:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageWebToJSON(
        value.imageWeb
      ),
    image_mobile:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsImageMobileToJSON(
        value.imageMobile
      ),
    top_image_mobile:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsTopImageMobileToJSON(
        value.topImageMobile
      ),
    square_image_mobile:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsSquareImageMobileToJSON(
        value.squareImageMobile
      ),
    hero_image:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsHeroImageToJSON(
        value.heroImage
      ),
    brand_logo:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsBrandLogoToJSON(
        value.brandLogo
      ),
    video: GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsVideoToJSON(
      value.video
    ),
    thumbnail:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsThumbnailToJSON(
        value.thumbnail
      ),
    uvc_image_web:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageWebToJSON(
        value.uvcImageWeb
      ),
    uvc_image_mobile:
      GetDisplayCampaignsIdParamDataAttributesAdGroupsAttributesCreativeAssetsUvcImageMobileToJSON(
        value.uvcImageMobile
      ),
  }
}
